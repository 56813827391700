import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="mt-10 flex flex-col items-center uppercase text-xs bg-neutral-200 border-t border-gray-300">
      <div className="flex flex-col md:block">
        <div className="inline-block px-3 mt-2 md:my-2 text-center md:text-left"><NavLink to="/condition-generale-utilisation">Conditions générales d’utilisation</NavLink></div>
        <div className="inline-block px-3 my-2 md:my-2 text-center md:text-left"><NavLink to="/politique-cookie">Politique de Cookies</NavLink></div>
        <div className="inline-block px-3 mb-0 md:my-2 text-center md:text-left"><NavLink to="/politique-de-confidentialite">Politique de confidentialité</NavLink></div>
      </div>
      <div className="mt-3 md:mt-0 mb-2 text-gray-500">© Copyright 2013-2022  Budget.bitbase.fr</div>
    </div>
  );
}
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import CategoriesSelect from "./CategoriesSelect";
import checked from '../icons/checked.svg';
import unchecked from '../icons/unchecked.svg';

export default function CreateRow({ categories, minDate, maxDate, hasDate, hasCheckbox }) {
  const [form, setForm] = useState({
    date: '',
    description: '',
    amount: '',
    isChecked: false
  });

  function handleChange(evt) {
    const { name, value } = evt.target;
    setForm({ ...form, [name]: value });
  }

  return (
    <tr>
      {
        !hasDate ? null : <td className="border-gray-300 border">
          <ReactDatePicker
            className="bg-transparent outline-none w-full"
            minDate={minDate}
            maxDate={maxDate}
            name='date'
            dateFormat="dd/MM/yyyy"
            locale='fr' />
        </td>
      }
      <td className="border-gray-300 border">
        <input className='px-1 w-16 min-w-full' onChange={(evt) => handleChange(evt)} name='description' type="text" />
      </td>
      <td className="border-gray-300 border">
        <CategoriesSelect categories={categories} />
      </td>
      <td className="border-gray-300 border">
        <input className='px-1 w-16 min-w-full' name='amount' onChange={(evt) => handleChange(evt)} type="text" />
      </td>
      {
        !hasCheckbox ? null :
          <td className="px-1 border-gray-300 border">
            <img className='w-5' src={form.isChecked === 1 ? checked : unchecked} alt="pointer ou dépointer" />
          </td>
      }
    </tr>
  );
}

import '../css/documents.css';

export default function TermsOfUse() {
  return (
    <div className='document md-box white-box text-justify my-10 py-12 border'>
      (Dernière mise à jour le 28 Janvier 2022)<br /><br />
      <h4>Introduction</h4>
      Bienvenue sur le site <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> qui sont détenus et
      exploités par Antoine Touchard. Cet accord exige le recours à l'arbitrage sur une base individuelle
      pour résoudre les différends, plutôt que des procès devant jury ou des recours collectifs, et limite
      également les recours à votre disposition en cas de différend.
      En accédant ou en utilisant le site, les applications mobiles (collectivement, les “sites”) fournis
      par <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>, vous acceptez d'être lié par ces conditions
      et tous les termes incorporés ici par référence. Si vous n'acceptez pas ces conditions, vous ne
      pouvez pas accéder ou utiliser les sites.
      VEUILLEZ LIRE TRÈS ATTENTIVEMENT TOUS LES TERMES ET CONDITIONS SUIVANTS
      D'UTILISATION DU SITE. EN ACCÉDANT ET/OU EN UTILISANT LE SITE, VOUS ACCEPTEZ D'ÊTRE
      LIÉ PAR TOUTES LES CONDITIONS D'UTILISATION ET LA POLITIQUE DE CONFIDENTIALITÉ
      SUIVANTES.
      Parties. Les conditions d'utilisation suivantes, ainsi que toutes autres directives, règles ou
      politiques d'exploitation que nous pouvons établir et publier sur le site de temps à autre, qui sont
      toutes incorporées aux présentes par référence. Tout ce qui précède, qui est collectivement
      appelé “l'accord”, énonce les termes et conditions d'un contrat juridiquement contraignant
      entre chaque visiteur et/ou utilisateur du site (ainsi que toute entreprise, entité ou organisation
      que chaque visiteur et/ou l'utilisateur peut représenter).
      <h4>Acceptation des conditions et accord d'être lié</h4>
      Les services vous sont offerts à la condition que vous acceptiez et acceptez d'être lié par cet
      accord dans son intégralité sans modification d'aucune sorte. En accédant et/ou en utilisant l'un
      de nos sites, vous acceptez d'être lié par cet accord, que vous soyez un "visiteur" (ce qui signifie
      que vous naviguez simplement sur un ou plusieurs de nos sites) ou que vous soyez un "utilisateur
      enregistré" (ce qui signifie que vous vous êtes inscrit pour utiliser le site et que vous avez souscrit
      à l'utilisation d'un ou plusieurs de nos services). Si vous n'acceptez pas les termes de cet accord,
      vous n'êtes pas autorisé à utiliser le site et/ou à vous abonner aux services, et vous êtes obligé
      de quitter tout le site et de cesser immédiatement toute utilisation du site et des services. .
      Conditions d'utilisation
      <h4>Utilisation du Site</h4>
      <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> sont propriétaires ou licenciés de tous les
      éléments de ce Site (“Éléments”). Le matériel est protégé par le droit d'auteur et une marque
      déposée, et toute utilisation non autorisée du matériel peut violer le droit d'auteur, la marque
      déposée et d'autres lois. Vous pouvez visualiser et télécharger les Documents uniquement pour
      votre usage personnel et non commercial, à condition que vous conserviez tous les droits
      d'auteur et autres avis de propriété contenus dans les Documents ou sur toute copie des
      Documents. Vous ne pouvez en aucun cas modifier les Documents ni les reproduire, les afficher
      publiquement, les distribuer ou les utiliser de toute autre manière à des fins publiques ou
      commerciales. Toute utilisation du matériel sur tout autre site Web ou environnement
      informatique en réseau à quelque fin que ce soit est interdite.
      <h4>Données de confidentialité</h4>
      Nous pouvons collecter et stocker des informations personnelles identifiables vous concernant
      lorsque vous visitez le site ("données personnelles"). Ces informations peuvent inclure, mais sans
      s'y limiter, des détails tels que l'identifiant (choisi lors de l'inscription), l'adresse IP, l'adresse email et la date de la dernière visite. Nous pouvons
      également collecter et stocker des informations vous concernant pour améliorer le site et
      améliorer l'interaction des utilisateurs en fonction des informations collectées.
      Toutes les données personnelles que nous collectons seront traitées conformément aux
      principes de la loi européenne sur la protection des données du 25 mai 2018 et à notre politique
      de confidentialité. En cas de conflit entre ces conditions et notre politique de confidentialité, la
      politique de confidentialité s'appliquera.
      <h4>Exigences générales d'inscription</h4>
      Les visiteurs ne sont actuellement autorisés à voir que des parties limitées du site avant de
      décider de devenir un utilisateur enregistré. L'accès complet au site et aux services est disponible
      uniquement pour les utilisateurs enregistrés et les abonnés payants. Si vous souhaitez devenir
      un Utilisateur Enregistré, vous devez vous inscrire sur notre Site, vous devez compléter les
      procédures d'inscription affichées sur la ou les pages d'inscription du Site et, le cas échéant, payer
      les frais d'abonnement requis. Certains aspects du site et des services sont disponibles pour tous
      les utilisateurs enregistrés, et certains ne sont disponibles que pour les utilisateurs enregistrés
      qui ont payé les frais d'abonnement requis, qui sont tous affichés sur le site.
      <h4>Conditions d'admissibilité/d'âge pour l'accès</h4>
      En accédant et en utilisant le Site, vous reconnaissez et acceptez que vous nous déclarez que vous
      n'êtes pas une personne interdite d'acheter ou de recevoir des Services, en vertu des lois
      françaises ou de toute autre juridiction applicable. L'inscription, l'abonnement, l'accès et
      l'utilisation du Site et des Services ne sont pas disponibles pour les utilisateurs interdits d'achat
      ou de réception des Services, en vertu des lois françaises ou de toute autre juridiction applicable
      et sont nuls là où la loi applicable l'interdit.
      <h4>Conditions requises pour devenir un utilisateur enregistré</h4>
      En complétant les procédures d'inscription affichées sur la ou les pages d'inscription du Site, vous
      reconnaissez et acceptez que (a) vous nous déclarez que vous fournissez des informations vraies,
      exactes, à jour et complètes sur vous-même, comme demandé par le formulaire d'inscription
      disponibles sur notre Site (les "Données d'inscription"), et (b) vous êtes tenu de conserver et de
      mettre à jour rapidement vos Données d'inscription pour qu'elles restent vraies, exactes, à jour
      et complètes. Si vous fournissez des informations fausses, inexactes, obsolètes ou incomplètes,
      ou si nous avons des motifs raisonnables de soupçonner que ces informations sont fausses,
      inexactes, obsolètes ou incomplètes, nous nous réservons le droit de suspendre ou de résilier
      votre compte et de refuser tout et tout accès et/ou utilisation actuel ou futur du Site et des
      Services.
      <h4>Informations de paiement et de facturation</h4>
      En utilisant le mode de paiement que nous acceptons, vous déclarez et
      garantissez que vous êtes autorisé à utiliser le mode de paiement désigné et que vous nous
      autorisez (ou notre processeur de paiement tiers) à facturer votre mode de paiement pour le
      montant total de votre abonnement ou autre achat (y compris les taxes applicables et autres
      frais). Si le mode de paiement ne peut pas être vérifié, est invalide ou autrement inacceptable,
      votre paiement peut être suspendu ou annulé. Vous devez résoudre tout problème que nous
      rencontrons afin de procéder à votre paiement.
      Vous reconnaissez que le montant facturé peut varier en raison d'offres promotionnelles, de
      modifications de votre plan ou de modifications des taxes applicables ou d'autres frais, et vous
      nous autorisez (ou notre processeur de paiement tiers) à facturer votre mode de paiement pour
      le montant correspondant.
      <h4>Utilisation du logiciel</h4>
      Tout logiciel disponible sur ce Site (« Logiciel ») est l'œuvre protégée par le droit d'auteur de
      <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>. La copie ou la reproduction du Logiciel sur tout
      autre serveur ou emplacement pour une reproduction ou une redistribution ultérieure est
      interdite, sauf si une telle reproduction ou redistribution est autorisée par un contrat de licence
      accompagnant ce Logiciel. Vous ne pouvez pas créer d'œuvres dérivées du Logiciel, ni tenter de
      décompiler ou de désosser le Logiciel, sauf autorisation contraire de la loi. L'utilisation du Logiciel
      est soumise aux conditions de licence de tout contrat de licence pouvant accompagner ou fourni
      avec le Logiciel.
      <h4>Politique de protection de la vie privée en ligne des enfants</h4>
      En raison de la nature d'Internet, nous ne pouvons pas interdire aux mineurs de visiter notre Site.
      Cependant, notre site ne s'adresse pas aux enfants de moins de 13 ans et nous ne collectons pas
      sciemment d'informations auprès d'enfants de moins de 13 ans via le site. Si vous pensez qu'un
      enfant nous a fourni des informations via le Site, veuillez nous contacter. Nous ferons de notre
      mieux pour supprimer toutes les informations fournies par l'enfant de notre système.
      <h4>Compte membre, mot de passe et sécurité</h4>
      Pour devenir un utilisateur enregistré, il vous sera demandé de créer un mot de passe et de
      fournir votre adresse e-mail, ainsi que de fournir des informations supplémentaires concernant
      votre compte personnel. Vous serez responsable du maintien et de la sécurisation de la sécurité
      et de la confidentialité de votre mot de passe et des informations de votre compte. Vous serez
      entièrement responsable de tout accès à l'utilisation de notre site et de nos services qui se
      produit sous votre compte ou votre mot de passe. Vous acceptez également de nous informer
      instantanément de toute utilisation non autorisée de votre compte, de votre mot de passe ou de
      toute forme de violation de la sécurité.
      <h4>Conservation et divulgation des informations de compte et du contenu de l'utilisateur</h4>
      Vous acceptez et reconnaissez que nous pouvons conserver, accéder et divulguer le contenu et
      les informations de votre compte utilisateur si cela est requis en toute bonne foi ou par la loi
      qu'un tel accès, divulgation ou conservation est très nécessaire pour :
      (a) se conformer à la juridiction légale ;
      (b) appliquer cet accord et ses conditions ;
      (c) Répondre aux réclamations selon lesquelles tout contenu du site viole les droits de tiers ;
      (d) Répondre à vos demandes de service client ; ou peut-être
      (e) Protéger les droits, la sécurité personnelle ou la propriété de <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>, des autres utilisateurs des sites et services, ainsi que du public.
      Cependant, veuillez garder à l'esprit que nous ne divulguerons jamais intentionnellement vos
      informations personnelles à des tiers, sauf indication contraire dans notre politique de
      confidentialité.
      <h4>Composants de sécurité</h4>
      Vous acceptez et reconnaissez que les sites et les services, ainsi que les logiciels incorporés dans
      la page Web et les services, peuvent sans préavis inclure des composants de sécurité qui
      permettront d'accéder, de protéger ou d'utiliser des ressources numériques, et l'utilisation de
      ces fonctionnalités de sécurité être soumis à des règles établies par nous ou par les fournisseurs
      de contenu qui nous fournissent du contenu. Vous êtes tenu de ne pas tenter de contourner ou
      d'outrepasser ces fonctions et règles de sécurité incorporées dans notre site et nos services.
      <h4>Contenu</h4>
      Le contenu disponible sur le site et le service se compose généralement de ;
      a. Contenus appartenant à <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>, y compris aux
      habillages commerciaux, marques et logo du site ainsi qu'au contenu créé et obtenu &
      qui nous appartient,
      b. Contenu appartenant à des tiers pour des utilisations spécifiées sur le site et les services
      ("contenu tiers"), et
      c. Contenu qui peut être publié ou autrement téléchargé sur le site et/ou les services par
      vous ("contenu utilisateur"). Entre vous et les autres utilisateurs enregistrés, cependant,
      le contenu publié par d'autres utilisateurs enregistrés est considéré comme du “contenu
      tiers”. Tous les éléments qui précèdent sont collectivement appelés “contenu du site”.
      <h4>Droits de propriété intellectuelle</h4>
      En utilisant ce site Web, vous acceptez que toutes les informations, communications,
      photographies, textes, images, graphiques, logiciels, marques, noms commerciaux, logos et
      autres éléments et services de ce site Web (le “contenu”) soient protégés par le droit d'auteur,
      la marque, droits de base de données et autres lois sur la propriété intellectuelle en vertu des
      lois nationales françaises et des traités internationaux.
      Vous acceptez de ne pas copier, modifier, télécharger, transmettre, afficher, distribuer, exécuter,
      reproduire, concéder sous licence, publier, créer des œuvres dérivées, transférer ou vendre toute
      information contenue sur ou faisant partie de ce site Web, ou utiliser autrement ce contenu de
      ce site Web pour la revente, la redistribution ou pour toute autre utilisation commerciale, sans
      notre consentement selon le cas.
      Vous pouvez accéder au site Web et au contenu selon leur disponibilité: pour votre information
      et votre usage personnel, comme prévu par la fonctionnalité normale du service. Pour le
      streaming, 'streaming' ou 'stream' désigne une transmission numérique contemporaine d'une
      œuvre audiovisuelle via Internet depuis notre service vers l'appareil d'un utilisateur de telle
      manière que les données sont destinées à être visionnées en temps réel et non destinées à être
      copiées, stockées, téléchargées en permanence ou redistribuées par l'utilisateur. L'accès aux
      vidéos du site Web à des fins ou de toute autre manière que le streaming est expressément
      interdit
      <h4>Conduite interdite</h4>
      Le site et les services peuvent inclure des fonctionnalités qui vous permettent de publier du
      contenu utilisateur, qui comprend et s'applique à votre propre contenu, des commentaires sur
      le contenu utilisateur publié par d'autres utilisateurs enregistrés et des communications avec
      d'autres utilisateurs enregistrés. Ce contenu utilisateur comprend, mais sans s'y limiter, des
      informations, des données, du texte, des logiciels, de la musique, du son, des photographies, des
      graphiques, des vidéos, des messages, des balises ou d'autres éléments. Vous reconnaissez et
      acceptez que vous n'utiliserez pas le site et/ou le service pour publier ou transmettre du contenu
      utilisateur qui:
      <ul>
        <li> Est manifestement offensant et/ou incite au racisme, au sectarisme, à la haine ou à des
          atteintes physiques de quelque nature que ce soit à l'encontre d'un groupe ou d'un
          individu</li>
        <li> Harcèle ou prône le harcèlement d'une autre personne</li>
        <li> Exploite les gens de manière sexuelle ou violente</li>
        <li> Contient de la nudité, de la violence ou un sujet offensant</li>
        <li> Fournit tous les numéros de téléphone, adresses postales, noms de famille ou adresses
          e-mail de toute personne autre que la vôtre</li>
        <li> Fait la promotion d'informations que vous savez fausses ou trompeuses ou fait la
          promotion d'activités ou de comportements illégaux qui sont abusifs, menaçants,
          obscènes, diffamatoires ou diffamatoires</li>
        <li> Enfreint toute propriété intellectuelle ou tout autre droit de propriété d'un tiers, y
          compris le contenu de l'utilisateur qui promeut une copie illégale ou non autorisée de
          l'œuvre protégée par le droit d'auteur d'une autre personne, comme la fourniture de
          programmes informatiques piratés ou des liens vers ceux-ci, la fourniture d'informations
          pour contourner la copie installée par la fabrication. protéger les appareils ou fournir de
          la musique piratée ou des liens vers des fichiers musicaux piratés</li>
        <li> Implique la transmission de "courrier indésirable", de "chaînes de lettres" ou de
          publipostage non sollicité, de messagerie instantanée ou de "spamming"</li>
        <li> Contient des pages d'accès restreint ou par mot de passe uniquement ou des pages ou
          des images cachées (celles qui ne sont pas liées à ou à partir d'une autre page accessible)</li>
        <li> Favorise ou promeut toute activité ou entreprise criminelle ou fournit des informations
          pédagogiques sur des activités illégales, y compris, mais sans s'y limiter, la fabrication ou
          l'achat d'armes illégales, la violation de la vie privée de quelqu'un ou la fourniture ou la
          création de virus informatiques</li>
        <li> Sollicite des mots de passe ou des informations d'identification personnelle à des fins
          commerciales ou illégales auprès d'autres membres</li>
        <li> implique des activités commerciales et/ou des ventes sans notre consentement écrit
          préalable, telles que des concours, des tirages au sort, du troc, de la publicité ou des
          systèmes pyramidaux</li>
        <li> Inclut une photographie d'une autre personne que vous avez publiée sans le
          consentement de cette personne ou, dans le cas d'enfants mineurs, le consentement
          parental, ou constitue autrement une atteinte à la vie privée d'un individu ou une
          violation des droits de publicité; ou</li>
        <li> Contient un virus ou un autre composant nuisible.</li>
      </ul>
      <h4>Responsabilité du contenu de l'utilisateur</h4>
      Vous, et non <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>, êtes entièrement responsable de
      tout le contenu utilisateur que vous téléchargez, publiez, envoyez par e-mail, transmettez ou
      mettez à disposition via le site et /ou les services. Nous ne contrôlons pas le contenu utilisateur
      publié via le site et/ou les services et, à ce titre, nous ne pouvons garantir et ne garantissons pas
      l'exactitude, l'intégrité ou la qualité de ce contenu utilisateur. Vous reconnaissez et acceptez
      qu'en utilisant le site et les services, vous pouvez être exposé à un contenu utilisateur que vous
      jugez offensant, indécent ou répréhensible. En aucun cas, notre service ne sera responsable de
      quelque manière que ce soit du contenu de l'utilisateur, y compris, mais sans s'y limiter, des
      erreurs ou des omissions dans tout contenu de l'utilisateur, ou de toute perte ou dommage de
      quelque nature que ce soit résultant de l'utilisation de tout utilisateur. le contenu publié, envoyé
      par e-mail, transmis ou autrement mis à disposition via le site et/ou les services.
      <h4>Politique de cookies</h4>
      Nos sites utilisent des cookies. Nos cookies ne contiennent aucune information personnellement
      identifiable, mais sans eux, certains éléments de nos sites peuvent ne pas fonctionner
      correctement. Les cookies sont une fonctionnalité standard des navigateurs Web modernes. Ce
      sont de petits fichiers qui sont stockés dans votre navigateur Web et utilisés pour faire
      fonctionner le site efficacement.
      Certains des cookies que nous utilisons sont essentiels au bon fonctionnement de nos sites - par
      exemple, garder une trace des articles qui ont été ajoutés à votre panier ou se souvenir de votre
      connexion. D'autres cookies peuvent être utilisés pour personnaliser votre expérience en ligne,
      suivre votre parcours via notre site (afin que nous puissions maximiser votre expérience et nous
      aider à comprendre comment nous pouvons l'améliorer) ou analyser le succès des promotions.
      D'autres cookies peuvent provenir de tiers tels que des sites de réseaux sociaux, des affiliés ou
      des partenaires. Pour plus d'informations sur notre politique de cookies, veuillez visiter notre
      page de politique de cookies.
      <h4>Rejet et/ou suppression du contenu de l'utilisateur</h4>
      Vous reconnaissez et acceptez que nous puissions ou non présélectionner le contenu utilisateur
      publié sur notre site, mais que nous aurons le droit, à sa seule discrétion, de présélectionner,
      modifier, refuser et/ou supprimer tout contenu utilisateur ou partie de celui-ci. qui est disponible
      via le site et/ou les services, à sa seule et absolue discrétion, pour quelque raison que ce soit, y
      compris, mais sans s'y limiter, sa détermination qu'un tel contenu d'utilisateur n'est pas
      approprié pour le site et/ou les services, ou pour aucun raison.

      Sans limiter ce qui précède, et à titre d'exemple uniquement, nous aurons le droit absolu de
      supprimer du site et/ou des services tout contenu d'utilisateur qui viole cet accord ou est
      autrement répréhensible à notre seule discrétion, ou de restreindre, suspendre, ou résilier votre
      accès à tout ou partie du site et/ou des services à tout moment, pour quelque raison que ce soit,
      avec ou sans préavis, et sans responsabilité. Si vous avez connaissance d'une mauvaise utilisation
      du site et/ou des services par toute personne, veuillez nous contacter.
      <h4>Licence limitée</h4>
      Nous accordons par la présente aux utilisateurs enregistrés une licence limitée, personnelle,
      révocable et non sous-licenciable pour afficher une seule copie de notre contenu et du contenu
      tiers situé sur ou disponible via notre site ou service (à l'exclusion de tout code logiciel)
      uniquement pour votre usage unique, personnel, non transférable et non commercial dans le
      cadre de la consultation de notre site et de l'utilisation des services pendant la durée de votre
      inscription. Toute utilisation de ce type est soumise aux termes et conditions énoncés dans le
      présent accord et peut être résiliée comme indiqué dans le présent accord. À l'exception du
      contenu utilisateur que vous publiez, vous ne pouvez pas copier, stocker, modifier, traduire,
      publier, diffuser, transmettre, distribuer, exécuter, afficher, mettre à disposition ou vendre tout
      contenu du site à quelque fin que ce soit.
      <h4>Exemples de limitations d'utilisation</h4>
      Toute réutilisation non commerciale de toute partie du contenu du site par un utilisateur
      enregistré qui relève de ce qui est autorisé comme « utilisation équitable » en vertu de la loi sur
      le droit d'auteur et les marques de commerce est autorisée. En plus des limitations énoncées
      dans les présentes conditions ci-dessus ("licence limitée"), et à titre d'exemple uniquement,
      aucun stockage, utilisation, reproduction ou transmission commerciale de tout contenu du site
      n'est autorisée en aucune circonstance. Le site et les services ne peuvent être utilisés en relation
      à des fins commerciales, sauf autorisation expresse écrite et préalable de notre représentant

      autorisé. Le cadrage non autorisé ou la création de liens vers l'un de nos sites est interdit. Les
      publicités commerciales et autres contenus commerciaux, liens d'affiliation et autres formes de
      sollicitation peuvent être supprimés du site et des services sans préavis et peuvent entraîner la
      résiliation de l'inscription et de l'abonnement et le refus d'accès et d'utilisation du site et des
      services.
      <h4>Réserve de droits</h4>
      Notre contenu et le contenu de tiers sont protégés par le droit d'auteur, les marques de
      commerce, les brevets, le secret commercial et d'autres lois. Nous et nos concédants de licence
      tiers possédons et conservons leurs droits, titres et intérêts respectifs sur leur contenu respectif,
      sous réserve uniquement de la licence limitée accordée aux utilisateurs enregistrés, comme
      indiqué ci-dessus.
      <h4>Lien vers des sites tiers et autres</h4>
      Le site peut contenir des publicités de tiers et/ou des liens vers d'autres sites appartenant à des
      tiers (c'est-à-dire des annonceurs, des partenaires affiliés, des partenaires stratégiques ou
      autres). Cependant, l'inclusion d'un lien dans l'un de nos sites n'implique pas notre enquête,
      notre évaluation et/ou notre approbation de ce site Web tiers. Nous ne sommes pas
      responsables de l'examen ou de l'évaluation, et nous ne garantissons pas les services ou les offres
      de ces entreprises, entités ou individus, ni l'exactitude du contenu de leur publicité ou de leur
      site. Nous n'assumons aucune responsabilité pour les actions, les services et le contenu de ces
      sites. Avant d'acheter des services auprès d'un tiers ou d'utiliser un site Web tiers, vous devez
      consulter les conditions d'utilisation et les politiques de confidentialité applicables à ce site. Si
      vous décidez d'accéder à un tel site lié, vous le faites à vos risques et périls.
      <h4>Utilisation internationale</h4>
      En raison de la nature globale d'Internet, vous reconnaissez et acceptez que vous êtes tenu de
      respecter toutes les lois, règles et réglementations applicables concernant l'accès et l'utilisation
      du site et des services. À titre d'exemple uniquement, vous reconnaissez et acceptez que vous
      êtes tenu de vous conformer à toutes les lois applicables concernant la transmission de données
      techniques exportées depuis le pays dans lequel vous résidez, et que des restrictions d'accès ou
      d'utilisation de certains contenus du site peuvent s'appliquer à utilisateurs en fonction de leur
      lieu de domicile, de résidence ou d'utilisation.
      <h4>Informations sur le site et avis de non-responsabilité</h4>
      Les informations du site concernant les services <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>,
      y compris leur disponibilité, leur apparence, leur prix et leurs spécifications sont susceptibles
      d'être modifiées sans préavis. Ces informations ne constituent pas une représentation, une
      garantie ou un autre engagement de notre part à l'égard de tout service et nous déclinons par la
      présente toute garantie, expresse ou implicite, quant à l'exactitude, l'adéquation à quelque fin
      que ce soit ou l'exhaustivité de ceux-ci.
      En aucun cas <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> ne pourront être tenus
      responsables de tout dommage spécial, indirect ou consécutif, ou de tout dommage quel qu'il
      soit résultant d'une perte d'usage, de données ou de bénéfices, que ce soit pour rupture de
      contrat, négligence ou autre action délictuelle ou toute autre cause d'action quelle qu'elle soit,
      découlant de ou en relation avec l'utilisation des informations disponibles sur ce site.
      Ce site Web peut contenir des déclarations prospectives et des informations relatives à
      <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> qui sont basées sur nos convictions ainsi que sur
      des hypothèses formulées par et des informations actuellement disponibles. à nous. Les mots
      “anticiper”, “croire”, “estimer”, “s'attendre à”, “avoir l'intention”, “volonté” et les expressions
      similaires, dans la mesure où ils nous concernent, visent à identifier les déclarations prospectives.
      Les résultats réels pourraient différer sensiblement de ceux projetés dans ces déclarations
      prospectives.
      <h4>Limitation de responsabilité</h4>
      En aucun cas, <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>, ne pourront être tenus
      responsables envers vous de tout dommage spécial, punitif, accessoire, indirect ou consécutif de
      quelque nature que ce soit, ou de quelque dommage que ce soit. , y compris, sans s'y limiter,
      ceux résultant de la perte d'utilisation, de données ou de bénéfices, que nous ayons été informés
      ou non de la possibilité de tels dommages, et de toute théorie de responsabilité, découlant de
      ou en relation avec l'utilisation de ce site ou de tout site Web référencé ou lié à partir de ce site.
      Certaines juridictions interdisent l'exclusion ou la limitation de responsabilité pour les dommages
      consécutifs ou accessoires, de sorte que les limitations ci-dessus peuvent ne pas s'appliquer à
      vous.
      <h4>Règles d'arbitrage et droit applicable</h4>
      L'arbitrage sera administré par la commission d'arbitrage française conformément au règlement
      d'arbitrage commercial et aux procédures complémentaires pour les litiges de consommation,
      alors en vigueur, sauf modification apportée par la présente section "règlement des litiges". La
      loi française sur l'arbitrage régira l'interprétation et l'application de la présente section.
      Lieu et procédure d'arbitrage
      Sauf si vous et <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> en conviennent autrement,
      l'arbitrage sera mené dans le département où <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> réside.
      <h4>Termes et résiliation</h4>
      Cet accord, tel qu'il peut être modifié de temps à autre, restera en vigueur et de plein effet aussi
      longtemps qu'il sera affiché sur l'un des sites ou jusqu'à sa résiliation par <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> ou par vous.
      Vous pouvez résilier votre statut d'utilisateur enregistré et annuler votre compte à tout moment
      et pour n'importe quelle raison. Si vous annulez votre compte avant l'expiration de votre période
      d'abonnement prépayé, vous perdrez les frais payés pour toute partie inutilisée de votre
      abonnement, qui ne sont pas remboursables. Vous reconnaissez et acceptez également que
      l'annulation de votre compte est votre seul droit.
      Nous nous réservons le droit de suspendre ou de résilier votre statut d'utilisateur enregistré,
      d'annuler votre compte et de désactiver votre accès au site et aux services à tout moment, avec
      ou sans préavis, avec ou sans motif et sans responsabilité envers vous.
      En cas de suspension ou de résiliation, nous n'aurons aucune obligation de sauvegarder tout
      contenu d'utilisateur que vous pourriez avoir publié ou autrement fourni, et nous nous réservons
      le droit de supprimer et de détruire définitivement vos informations personnelles et/ou votre
      contenu d'utilisateur.
      <h4>Vos obligations</h4>
      Vous acceptez d'utiliser les Services uniquement à des fins licites. Vous acceptez de ne pas
      interrompre ou tenter d'interrompre le fonctionnement des Services ou des Services de quelque
      manière que ce soit. Toute conduite de votre part qui, à notre seule discrétion, restreint, inhibe
      ou interfère avec la capacité de tout autre utilisateur à profiter des Services ou porte atteinte à
      la vie privée de tout autre utilisateur des Services ne sera pas tolérée, y compris par le biais de
      piratage ou en dégradant toute partie des Services, ou en se livrant à des spams, des inondations
      ou d'autres activités perturbatrices. Il vous est strictement interdit de communiquer sur ou via
      les Services tout contenu illégal, nuisible, offensant, menaçant, abusif, calomnieux, harcelant,
      diffamatoire, vulgaire, obscène, profane, haineux, frauduleux, sexuellement explicite,
      homophobe, raciste, ethnique ou autrement répréhensible. matériel de toute sorte, y compris,
      mais sans s'y limiter, tout matériel qui encourage une conduite qui constituerait une infraction
      pénale, donnerait lieu à une responsabilité civile ou violerait autrement toute loi locale, étatique,
      nationale ou internationale applicable.
      En créant un compte, vous êtes seul responsable du maintien de la confidentialité de vos
      informations d'accès, y compris votre mot de passe, ainsi que de l'exactitude, de l'exhaustivité et
      de l'actualité de toutes les données de votre compte. Vous ne pouvez pas utiliser ou interférer
      de quelque manière que ce soit avec le compte, le nom d'utilisateur ou le mot de passe d'un
      autre utilisateur à tout moment.
      Nous nous réservons le droit de résilier ou de suspendre les présentes Conditions sans préavis, si
      nous pensons, à notre seule discrétion, que vos actions enfreignent toute loi applicable ou
      qu'elles sont préjudiciables à nos intérêts ou aux intérêts, y compris la propriété intellectuelle ou
      d'autres droits, d'un autre utilisateur des Services ou d'autres partenaires tiers, affiliés, sponsors,
      fournisseurs, concédants de licence ou marchands.
      <h4>Renonciation aux réclamations</h4>
      Par la présente, vous renoncez, nous libérez et nous déchargez de toute réclamation que vous
      pourriez être en droit de faire en raison de toute utilisation de votre contenu d'utilisateur
      autorisée en vertu du présent accord et/ou en raison de votre participation à l'une des
      fonctionnalités de communication et de réseautage social. des services, y compris, mais sans s'y
      limiter, et à titre d'exemple uniquement, toute réclamation fondée sur la violation du droit
      d'auteur, la violation de la marque, les droits à la vie privée ou à la publicité, la diffamation et
      autres.
      <h4>Licence et accès</h4>
      Vous pouvez utiliser le site Web et les informations, écrits, images et/ou autres travaux que vous
      voyez, entendez ou expérimentez autrement sur le site Web (individuellement ou
      collectivement, le « Contenu ») uniquement à des fins personnelles et non commerciales. et/ou
      pour en savoir plus sur nos services. Aucun droit, titre ou intérêt dans tout Contenu ne vous est
      transféré, que ce soit à la suite du téléchargement de ce Contenu ou autrement. Nous nous
      réservons le titre complet et tous les droits de propriété intellectuelle sur tout le contenu. Sauf
      autorisation expresse des présentes conditions d'utilisation, vous ne pouvez pas utiliser,
      modifier, copier, distribuer, transmettre ou dériver une autre œuvre de tout contenu obtenu à
      partir du site Web, sauf autorisation expresse des présentes conditions d'utilisation.
      <h4>Informations qui nous sont fournies</h4>
      Nous ne voulons pas que vous nous envoyiez, et vous ne devriez pas, nous envoyer
      d'informations confidentielles ou exclusives via le site Web. Toute soumission de votre part, y
      compris, mais sans s'y limiter, les questions, commentaires, suggestions ou autres, sera
      considérée comme non confidentielle et deviendra notre propriété. De plus, par votre
      soumission, vous nous accordez une licence illimitée et irrévocable pour utiliser, reproduire,
      afficher, exécuter, modifier, transmettre et distribuer ces informations. Nous serons libres
      d'utiliser les idées, concepts, savoir-faire ou techniques contenus dans ces informations à
      quelque fin que ce soit, y compris, mais sans s'y limiter, le développement, la fabrication et la
      commercialisation de produits incorporant ces informations.
      <h4>PCI DSS Conformité RGPD</h4>
      Normes de sécurité des données de l'industrie des cartes de paiement (PCI DSS) fait référence
      à la norme d'information mondiale établie par l'industrie des cartes de paiement pour aider à la
      prévention de la fraude par carte de paiement.
      Nos atouts les plus précieux sont nos utilisateurs et nos partenaires ; nous apprécions et nous
      soucions de leur sécurité, et pour cette raison, nous avons entrepris le processus très rigoureux
      de devenir certifié PCI DSS à l'échelle mondiale.
      <h4>Limitation de responsabilité</h4>
      Dans toute la mesure permise par les lois applicables, nous ne serons en aucun cas responsables
      envers vous ou un tiers pour tout dommage indirect, consécutif, exemplaire, accessoire, spécial
      ou punitif, y compris les dommages pour manque à gagner résultant de ou en relation avec toute
      utilisation de le site et/ou les services, et/ou tout site Internet auquel ils sont liés, et/ou tout
      contenu, information, service ou service accessible via le site et/ou les services.
      <h4>Severability</h4>
      Certaines juridictions n'autorisent pas l'exclusion de certaines garanties ou la limitation ou
      l'exclusion de responsabilité pour les dommages accessoires ou consécutifs. Par conséquent,
      dans certaines juridictions, certaines des limitations de responsabilité ci-dessus peuvent ne pas
      s'appliquer à vous. Dans un tel cas, et / ou si des termes ou conditions de cet accord sont jugés
      invalides pour quelque raison que ce soit, cet accord restera en vigueur et de plein effet sur ses
      termes et conditions restants de cet accord à moins et jusqu'à ce que nous le résiliions. .
      En outre, si un terme ou une condition de cet accord est jugé invalide par un tribunal compétent,
      les parties conviennent néanmoins que le tribunal doit s'efforcer de donner effet aux intentions
      des parties telles que reflétées dans la disposition invalidée.
      <h4>Indemnité</h4>
      Vous acceptez d'indemniser, de défendre et d'exonérer <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> de toute réclamation, perte, responsabilité, dommage et/ou coût de tiers ( y compris
      les honoraires et frais d'avocat raisonnables) résultant de votre violation des termes de cet
      accord, ou de votre violation, ou de la violation par tout autre utilisateur de votre compte, de
      toute propriété intellectuelle ou autre droit de toute personne ou entité. Nous déploierons nos
      meilleurs efforts raisonnables pour vous informer rapidement de toute réclamation, perte,
      responsabilité ou demande, et vous fournirons une assistance raisonnable, à vos frais, pour
      défendre une telle réclamation, perte, responsabilité, dommage ou coût.
      <h4>Politique de confidentialité</h4>
      Notre politique de confidentialité détaille la manière dont nous collectons et utilisons vos
      informations. Vous devriez le consulter si vous souhaitez en savoir plus sur la façon dont nous
      recueillons, utilisons et traitons vos informations. Nous respectons la vie privée de tous les
      utilisateurs de notre site Web. Lorsque vous accédez ou utilisez le site Web ou les services, vous
      signifiez votre accord avec notre politique de confidentialité.
      Cependant, nous utiliserons les informations fournies lors du processus d'inscription uniquement
      dans le but de maintenir votre inscription. Vos informations d'enregistrement ne seront pas
      divulguées à des tiers, sauf si cela peut être nécessaire pour l'application de cet accord, ou
      autrement autorisé ou requis par la loi applicable. Pour plus d'informations, veuillez visiter notre
      page de politique de confidentialité.
      <h4>Politique de protection des données</h4>
      Cette politique vise à fournir aux visiteurs de notre site Web des informations claires et détaillées
      sur la manière dont leurs données personnelles sont traitées et, en particulier, sur les types de
      données traitées, la base juridique du traitement des données et leurs droits et recours légaux
      liés à la Loi sur le traitement des données GDPR de l'UE.
      <h4>Confidentialité, sécurité conformément à la loi sur la protection des données GDPR de l'UE</h4>
      Nous nous engageons à protéger la confidentialité de vos informations personnelles et celles de
      nos utilisateurs. En utilisant nos services, vous reconnaissez et acceptez que la collecte, l'âge et
      la divulgation de ces informations personnelles sont régies par nos conditions et notre politique
      de droit d'auteur. De plus, si : (a) vous êtes établi dans l'Espace économique européen (EEE) ; ou
      (b) vous êtes autrement soumis aux exigences du Règlement général sur la protection des
      données de l'UE, notre collecte et notre utilisation des informations personnelles de tout résident
      européen sont également soumises à notre addendum sur le traitement des données.
      Nous collectons et utilisons les données personnelles des utilisateurs dans la mesure nécessaire
      à la création de services ou à la modification des conditions contractuelles de notre site internet
      entre l'utilisateur et <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> et https://check-pvp .com/.
      <h4>Conditions supplémentaires</h4>
      Nous pouvons également vous demander de suivre des règles, directives ou autres conditions
      supplémentaires (“conditions supplémentaires”) afin de participer à certaines promotions ou
      activités disponibles sur notre site, d'obtenir certains contenus de site premium via notre site
      et/ou pour d'autres raisons. . En outre, certaines conditions supplémentaires régiront votre
      abonnement aux services et tout achat que vous effectuerez via notre site Web. Ces conditions
      supplémentaires seront publiées sur les parties pertinentes de notre site ou sur les parties de
      notre site qui décrivent les promotions, le contenu ou les activités spécifiques. Ces conditions
      supplémentaires sont incorporées par référence dans le présent accord, et vous acceptez de les
      respecter lorsque vous participez à ces promotions, achetez des articles dans nos boutiques en
      ligne ou vous engagez dans des activités régies par ces conditions supplémentaires.
      Nous nous réservons le droit, à tout moment, de modifier, éditer, supprimer, suspendre ou
      interrompre, temporairement ou définitivement, le site et/ou les services, ou toute partie de
      ceux-ci, avec ou sans préavis. Vous acceptez que nous ne serons pas responsables envers vous
      ou envers un tiers
      <h4>Accord complet</h4>
      Cet accord, ainsi que toutes les autres conditions de service référencées ici (y compris, sans s'y
      limiter, la politique de confidentialité, la politique de cookies et les conditions supplémentaires),
      constitue l'intégralité de l'accord entre vous et <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> et https://check -pvp.com/
      et régit votre utilisation du site et du service, remplaçant tout accord antérieur entre vous et
      <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> concernant le site et/ou les services.
      <h4>Choix de la loi et loi applicable</h4>
      Le présent accord et la relation entre vous et <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a>
      seront régis par les tribunaux français applicables, sans égard à ses dispositions en matière de
      conflit de lois, y compris celles requises par le Règlement général de l'UE sur la protection des
      données (RGPD) et de chaque pays ou juridiction dans lequel nous visons à faire des affaires, y
      compris la France.
      Si vous pensez qu'il ne satisfait pas à la loi de votre juridiction, nous aimerions avoir de vos
      nouvelles. Cependant, en fin de compte, c'est à vous de décider si vous souhaitez utiliser notre
      site Web. Vous et <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> acceptez de vous soumettre
      à la compétence personnelle et exclusive des tribunaux situés en France.
      Les présentes conditions d'utilisation sont régies et interprétées conformément aux lois
      françaises. Et vous vous soumettez par la présente à la compétence exclusive des tribunaux
      fédéraux en France. Cela ne nous empêchera pas de poursuivre une réclamation pour rupture de
      contrat, violation du droit d'auteur ou autre en ce qui concerne ces termes et conditions dans
      toute autre juridiction à travers le monde.
      <h4>Aucune renonciation</h4>
      L'incapacité de <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> à exercer ou à appliquer tout
      droit et/ou recours en vertu du présent accord ne constitue pas une renonciation à ce droit et/ou
      recours.
      <h4>Délai de prescription</h4>
      Vous reconnaissez et acceptez que, indépendamment de tout statut ou loi contraire, toute
      réclamation ou cause d'action découlant de ou liée à l'utilisation du site, des services ou de cet
      accord doit être déposée dans un délai d'un (1) an après ladite réclamation ou cause d'action est
      née ou être à jamais prescrite.
      <h4>Relation des parties</h4>
      Vous et <a href="https://budget.bitbase.fr" target="_BLANK" rel="noreferrer">https://budget.bitbase.fr</a> reconnaissez et acceptez qu'ils sont des
      parties contractantes indépendantes traitant sans lien de dépendance dans le cadre de l'octroi
      de licences de droits de propriété intellectuelle et de la fourniture de services. Aucun partenariat,
      coentreprise, paternité conjointe, emploi, fiduciaire, agence ou autre relation n'est créé entre
      eux.
      <h4>Obligation pour les successeurs</h4>
      Le présent accord lie et s'applique au profit des héritiers, exécuteurs testamentaires,
      administrateurs, titulaires de licence, successeurs et/ou ayants droit des parties, selon le cas.
      Pour éviter tout doute, vous reconnaissez et acceptez que nous sommes pleinement autorisés à
      céder, sous-licencier et/ou autrement transmettre et transférer cet accord et/ou l'un de ses
      droits en vertu de cet accord à tout moment, à sa seule et absolue discrétion, et sans une
      indemnisation de quelque nature que ce soit.
      <h4>Avis et questions</h4>
      Si vous avez des questions concernant cet accord, veuillez nous contacter via ou sur la page du
      site Web.
    </div>
  );
}
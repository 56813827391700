import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import angleLeft from '../icons/angle-left.svg';
import angleRight from '../icons/angle-right.svg';
import Tooltip from "./Tooltip";

export default function DateNav({ handleDateUpdated }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const date = getInitialDate();
    handleChangeDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialDate = () => {
    let initialDate = new Date();
    if (searchParams.get('month') && searchParams.get('year')) {
      initialDate = new Date(`${searchParams.get('year')}-${parseInt(searchParams.get('month'))}-01`);
    }
    return initialDate;
  }

  const changeMonth = (NumberOfMonths) => {
    let newDate = currentDate.setMonth(currentDate.getMonth() + NumberOfMonths);
    handleChangeDate(new Date(newDate));
  }

  const handleChangeDate = (date) => {
    const urlParams = `?month=${date.getMonth() + 1}&year=${date.getFullYear()}`;
    navigate({
      search: urlParams,
    });
    setCurrentDate(date);
    handleDateUpdated(urlParams, date);
  }

  return (
    <div className="flex flex-row items-center">
      <Tooltip content="Accéder au mois précédent" direction="top"><img className='w-3 cursor-pointer' onClick={() => changeMonth(-1)} src={angleLeft} alt="mois précédent" /></Tooltip>
      <DatePicker
        className="uppercase text-center w-40 border-2 border-black rounded mx-2"
        selected={currentDate}
        onChange={(date) => handleChangeDate(date)}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        locale='fr'
        todayButton="Mois courant"
      />
      <Tooltip content="Accéder au mois suivant" direction="top"><img className='w-3 cursor-pointer' onClick={() => changeMonth(1)} src={angleRight} alt="mois suivant" /></Tooltip>
    </div>
  );
}
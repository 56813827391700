import { NavLink } from "react-router-dom";
import ellipsis from '../icons/ellipsis.svg';
import DisplayAmount from "./DisplayAmount";

export default function AccountCard({ account }) {
  return (
    <div style={{ maxWidth: 300 }} className="inline-block w-full m-auto mx-3 hover:scale-105 ease-in duration-200">
      <NavLink to={'/compte/' + account?.id} className="relative white-box xs2-box m-2 flex flex-col justify-between h-32 p-3 rounded-none rounded-r-lg border"
        style={{ border: '1px solid ' + account?.color + '60', borderLeft: '8px solid ' + account?.color, backgroundColor: account?.color + '40' }}>
        <div className="absolute right-4"><img src={ellipsis} className='ml-3 inline-block w-4' alt="voir option" /></div>
        <div className="text-md font-semibold">{account?.title}</div>
        <div className="self-end"><span className="text-2xl">{<DisplayAmount value={parseFloat(account?.incomesCheckedSum) - parseFloat(account?.expensesCheckedSum) + parseFloat(account?.initialValue)} />}</span> €</div>
      </NavLink>
    </div>
  );
}

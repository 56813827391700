import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountRow from "../components/AccountRow";
import CreateRow from "../components/CreateRow";
import SubMenuFixedRows from "../components/SubMenuFixedRows";
import Api from "../services/Api";

export default function FixedRows() {
  const { accountId } = useParams();
  const [account, setAccount] = useState({});
  // to remove 
  const [minDate] = useState(new Date());
  const [maxDate] = useState(new Date());
  const handleRowUpdated = () => {

  }
  // end to remove

  useEffect(() => {
    const getFixedRows = async () => {
      const getAccount = await Api.get(`/accounts/with-fixed-rows/${accountId}`);
      const accountCloned = getAccount.data;
      accountCloned.categories_depense = accountCloned.categories.filter(row => row.type === 1);
      accountCloned.categories_revenu = accountCloned.categories.filter(row => row.type === 2);
      accountCloned.depenses = accountCloned.fixedRows.filter(row => row.type === 1);
      accountCloned.revenus = accountCloned.fixedRows.filter(row => row.type === 2);
      accountCloned.sumDep = accountCloned.depenses.filter(row => row.category.neutral === 0).map(row => parseFloat(row.amount)).reduce((acc, val) => acc + val, 0);
      accountCloned.sumRev = accountCloned.revenus.filter(row => row.category.neutral === 0).map(row => parseFloat(row.amount)).reduce((acc, val) => acc + val, 0);
      accountCloned.difference = accountCloned.sumRev - accountCloned.sumDep;
      setAccount(accountCloned);
    }
    getFixedRows();
  }, []);

  return (
    <div className='grow'>
    <SubMenuFixedRows account={account} />
      <div className="flex flex-col">
        <div className='p-2 md:p-6'>
          <table className='text-sm'>
            <thead>
              <tr>
                <th className='px-1 font-normal text-left w-96'>Libellé</th>
                <th className='px-1 font-normal text-left w-32'>Catégorie</th>
                <th className='px-2 font-normal text-right w-32'>Montant</th>
                <th className='px-1 font-normal text-left w-6'></th>
              </tr>
            </thead>
            <tbody>
              {
                account.depenses && account.depenses?.map((row) =>
                  <AccountRow key={row.id} minDate={minDate} maxDate={maxDate} row={row} categories={account.categories_depense} handleRowUpdated={(rowId, name, value) => handleRowUpdated(rowId, name, value)} />
                )
              }
              {
                <CreateRow minDate={minDate} maxDate={maxDate} categories={account.categories_depense} />
              }
            </tbody>
          </table>


          <table className='text-sm mt-10'>
            <thead>
              <tr>
                <th className='px-1 font-normal text-left w-96'>Libellé</th>
                <th className='px-1 font-normal text-left w-32'>Catégorie</th>
                <th className='px-2 font-normal text-right w-32'>Montant</th>
                <th className='px-1 font-normal text-left w-6'></th>
              </tr>
            </thead>
            <tbody>
              {
                account.revenus?.map((row) =>
                  <AccountRow key={row.id} minDate={minDate} maxDate={maxDate} row={row} categories={account.categories_revenu} handleRowUpdated={(rowId, name, value) => handleRowUpdated(rowId, name, value)} />
                )
              }
              {
                <CreateRow minDate={minDate} maxDate={maxDate} categories={account.categories_revenu} />
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
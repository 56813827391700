import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import MenuLogged from "./MenuLogged";
import MenuOffline from "./MenuOffline";

export default function Menu() {

  const { user, selectedAccount } = useContext(UserContext);
  return (
    <div style={{ backgroundColor: (selectedAccount?.color + '40' || 'white'), borderBottom: '1px solid ' + (selectedAccount?.color !== '#FFFFFF' ? selectedAccount?.color + '60' : '#e0e0e0') }} className="flex flex-row justify-between items-center px-4 py-2 border-b bg-white">
      {user?.id ? <MenuLogged selectedAccount={selectedAccount} /> : <MenuOffline />}
    </div>
  );
}

import '../css/documents.css';

export default function PrivacyPolicy() {
  return (
    <div className='document md-box white-box text-justify my-10 py-12 border'>
      (Dernière mise à jour le 28 Janvier 2022)

      <h4>Aperçu</h4>
      Les données personnelles collectées par <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> sont traitées conformément à la loi sur la protection juridique des données personnelles des tribunaux français et d'autres actes juridiques. Toutes les données personnelles collectées à partir de ce site sont conformes aux principes de la loi européenne GDPR sur la protection des données de 1998 et du 25 mai 2018. En accédant à ce site, vous acceptez les termes de cette politique de confidentialité et consentez à la collecte, au traitement, à l'utilisation ou au transfert de données telles qu'énoncées dans cette politique.

      Aux fins du traitement des données personnelles, <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> peuvent engager des sous-traitants et/ou, à leur seule discrétion, embaucher d'autres personnes pour exécuter certaines fonctions. en notre nom. Dans de tels cas, nous prendrons les mesures nécessaires pour nous assurer que ces données sont traitées par les processeurs de données personnelles conformément à nos instructions et à la législation applicable. Nous exigerons également des processeurs de données personnelles qu'ils mettent en œuvre des mesures appropriées pour la sécurité des données personnelles. Dans de tels cas, nous veillerons à ce que ces personnes soient soumises à l'obligation de non-divulgation et ne pourront pas utiliser ces informations à d'autres fins, sauf dans la mesure nécessaire à l'exécution des fonctions qui leur sont assignées.

      <h4>Comment nous sommes conformes au règlement RGPD de l'UE</h4>
      Ceci est un avis pour vous informer de notre politique concernant toutes les informations que nous enregistrons à votre sujet. Elle définit les conditions dans lesquelles nous pouvons traiter toute information que nous recueillons auprès de vous ou que vous nous fournissez. Il couvre les informations qui pourraient vous identifier ("informations personnelles") et les informations qui ne le pourraient pas. Dans le contexte de la loi et de cet avis, “traiter” signifie collecter, stocker, transférer, utiliser ou autrement agir sur des informations.

      <ol>
        <li>Nous regrettons que s'il y a un ou plusieurs points ci-dessous qui ne vous satisfont pas, votre seul recours est de quitter notre site Web immédiatement.</li>
        <li>Nous prenons au sérieux la protection de votre vie privée et de votre confidentialité. Nous comprenons que tous les visiteurs de notre site Web ont le droit de savoir que leurs données personnelles ne seront pas utilisées à des fins non prévues par eux et ne tomberont pas accidentellement entre les mains d'un tiers.</li>
        <li>Nous nous engageons à préserver la confidentialité de toutes les informations que vous nous fournissez, et espérons que vous nous rendrez la pareille.</li>
        <li>Notre politique est conforme aux juridictions françaises mises en œuvre en conséquence, notamment celles requises par le Règlement général sur la protection des données (RGPD) et le Règlement sur la protection des données de l'Union européenne.</li>
        <li>La loi nous oblige à vous informer de vos droits et de nos obligations envers vous en ce qui concerne le traitement et le contrôle de vos données personnelles.</li>
        <li>Sauf indication contraire ci-dessous, nous ne partageons, ni ne vendons, ni ne divulguons à des tiers les informations collectées via notre site Web.</li>
      </ol>

      Nos opérations sont conformes au Règlement général sur la protection des données (RGPD) de l'Union européenne, en vigueur depuis le 25 mai 2018. Nous avons fait du RGPD une priorité, et nous sommes et avons toujours été pleinement alignés sur le résultat visé par le règlement : la protection de vos vie privée et données personnelles

      Cette politique (ainsi que nos conditions d'utilisation et tout autre document auquel elle fait référence) énonce:

      <ol>
        <li>Vue d'ensemble</li>
        <li>Comment nous nous conformons au règlement RGPD de l'UE</li>
        <li>Les informations que nous recueillons à votre sujet.</li>
        <li>Cookies et autres technologies.</li>
        <li>Comment nous utilisons vos informations.</li>
        <li>Nos mises à jour et communications promotionnelles.</li>
        <li>À qui nous communiquons vos informations.</li>
        <li>Où nous stockons vos informations.</li>
        <li>Traitement des paiements</li>
        <li>Comment nous protégeons vos informations</li>
        <li>Combien de temps nous conservons vos informations.</li>
        <li>Vos droits.</li>
        <li>Conformité au RGPD de l'Union européenne (avis sur le traitement des données)</li>
        <li>Plaintes auprès d'une autorité de protection des données</li>
        <li>Contactez-nous</li>
        <li>Modifications de cette politique.</li>
      </ol>
      Veuillez lire attentivement ce qui suit pour comprendre nos points de vue et nos pratiques concernant vos données personnelles et la manière dont nous les traiterons.
      En utilisant la Plateforme, vous reconnaissez avoir lu et compris la présente Politique de confidentialité.

      Aux fins de la loi sur la protection des données de l'Espace économique européen (la "loi sur la protection des données"), le responsable du traitement est

      <h4>Informations que nous recueillons à votre sujet</h4>
      Nous collectons des informations vous concernant lorsque vous nous les fournissez, lorsque vous utilisez nos Services et lorsque d'autres sources nous les fournissent, comme décrit plus en détail ci-dessous.

      <h4>Informations que vous nous fournissez</h4>
      Nous collectons des informations vous concernant lorsque vous les saisissez dans les Services ou que vous nous les fournissez directement.

      <strong>Informations sur le compte et le profil:</strong> nous collectons des informations vous concernant lorsque vous créez un compte, créez ou modifiez votre profil, définissez vos préférences, vous inscrivez ou effectuez des achats via les Services. Vous avez également la possibilité d'ajouter un nom d'affichage Nous gardons une trace de vos préférences lorsque vous sélectionnez des paramètres dans les Services.
      <br />
      <br />
      <strong>Contenu que vous fournissez via nos sites Web:</strong> Les Services incluent également nos sites Web que nous possédons ou exploitons. Nous recueillons d'autres contenus que vous soumettez à ces sites Web, qui comprennent des sites Web de médias sociaux ou de réseaux sociaux que nous exploitons. Par exemple, vous nous fournissez du contenu lorsque vous fournissez des commentaires ou lorsque vous participez à des fonctionnalités interactives, des sondages, des concours, des promotions, des tirages au sort, des activités ou des événements.
      <br />
      <br />
      <strong>Informations que vous fournissez via nos canaux d'assistance:</strong> les Services incluent également notre assistance clientèle, où vous pouvez choisir de soumettre des informations concernant un problème que vous rencontrez avec un Service. Que vous vous désigniez comme contact technique, que vous ouvriez un ticket d'assistance, que vous parliez directement à l'un de nos représentants ou que vous communiquiez avec notre équipe d'assistance, il vous sera demandé de fournir des informations de contact, un résumé du problème que vous rencontrez et tout autre documentation, captures d'écran ou informations utiles pour résoudre le problème.
      <br />
      <br />
      <strong>Informations que nous collectons automatiquement lorsque vous utilisez les Services</strong>
      Nous collectons des informations vous concernant lorsque vous utilisez nos Services, notamment en naviguant sur nos sites Web et en effectuant certaines actions au sein des Services.
      <br />
      <br />
      <strong>Votre utilisation des Services:</strong> Nous gardons une trace de certaines informations vous concernant lorsque vous visitez et interagissez avec l'un de nos Services. Ces informations incluent les fonctionnalités que vous utilisez ; les liens sur lesquels vous cliquez ; le type, la taille et les noms de fichiers des pièces jointes que vous téléchargez sur les Services ; les termes de recherche fréquemment utilisés ; et comment vous interagissez avec les autres sur les Services.
      <br />
      <br />
      <strong>Informations sur l'appareil et la connexion:</strong> nous recueillons des informations sur votre ordinateur, votre téléphone, votre tablette ou d'autres appareils que vous utilisez pour accéder aux Services. Ces informations sur l'appareil incluent votre type de connexion et vos paramètres lorsque vous installez, accédez, mettez à jour ou utilisez nos Services. Nous collectons également des informations via votre appareil concernant votre système d'exploitation, votre type de navigateur, votre adresse IP et les identifiants de votre appareil. Nous utilisons votre adresse IP et/ou votre préférence de pays afin d'approximer votre emplacement pour vous offrir une meilleure expérience de service. La quantité de ces informations que nous recueillons dépend du type et des paramètres de l'appareil que vous utilisez pour accéder aux Services.
      <br />
      <br />

      <strong>Cookies et autres technologies de suivi:</strong> nous et nos partenaires tiers, tels que nos partenaires publicitaires, de paiement et d'analyse, utilisons des cookies et d'autres technologies de suivi (par exemple, des balises Web, des identifiants d'appareil et des pixels) pour fournir des fonctionnalités et vous reconnaître à travers différents Services et appareils. Pour plus d'informations, veuillez consulter notre Avis sur les cookies et le suivi, qui comprend des informations sur la façon de contrôler ou de désactiver ces cookies et technologies de suivi.

      <strong>Informations que nous recevons d'autres sources</strong>
      Nous recevons des informations vous concernant d'autres utilisateurs du Service, de services tiers, de nos sociétés liées et de nos partenaires commerciaux et de distribution.

      <strong>Autres utilisateurs des Services:</strong> D'autres utilisateurs de nos Services peuvent fournir des informations vous concernant lorsqu'ils soumettent du contenu via les Services. Nous recevons également votre adresse e-mail des autres utilisateurs du Service lorsqu'ils la fournissent afin de vous inviter aux Services. De même, un administrateur peut fournir vos informations de contact lorsqu'il vous désigne comme contact de facturation ou technique sur le compte de votre entreprise.

      <h4>Cookies et autres technologies</h4>
      Nos Plateformes utilisent des cookies pour collecter et stocker certaines informations. Il s'agit généralement d'éléments d'information ou de code qu'un site Web transfère ou accède depuis le disque dur de votre ordinateur ou votre appareil mobile pour stocker et parfois suivre des informations vous concernant. Les cookies et technologies similaires permettent de se souvenir de vous lorsque vous utilisez cet ordinateur ou appareil pour interagir avec des sites Web et des services en ligne et peuvent être utilisés pour gérer une gamme de fonctionnalités et de contenus ainsi que pour stocker des recherches et présenter un contenu personnalisé.
      <br />
      <br />
      Nos Plateformes utilisent des cookies et des technologies similaires pour vous distinguer des autres utilisateurs de nos Plateformes. Cela nous aide à vous offrir une bonne expérience lorsque vous naviguez sur notre Plateforme et nous permet également d'améliorer nos Plateformes.
      <br />
      <br />
      La plupart des navigateurs Web acceptent automatiquement les cookies et les technologies similaires, mais si vous préférez, vous pouvez modifier votre navigateur pour empêcher cela et votre écran d'aide vous indiquera comment procéder. Nous vous donnons également des informations sur la désactivation des cookies. Cependant, vous ne pourrez peut-être pas profiter pleinement de notre site Web si vous le faites.
      <br />
      <br />
      Un certain nombre de cookies et de technologies similaires que nous utilisons ne durent que pendant la durée de votre session Web ou d'application et expirent lorsque vous fermez votre navigateur ou quittez l'application. D'autres sont utilisés pour se souvenir de vous lorsque vous revenez sur la Plateforme et dureront plus longtemps.
      <br />
      <br />
      Nous utilisons ces cookies et d'autres technologies au motif qu'ils sont nécessaires à l'exécution d'un contrat avec vous, ou parce que leur utilisation est dans notre intérêt légitime (lorsque nous avons considéré que ceux-ci ne sont pas supplantés par vos droits), et, en certains cas, lorsque la loi l'exige, où vous avez consenti à leur utilisation. Pour en savoir plus sur notre politique de cookies, veuillez visiter notre page de politique de cookies.

      <h4>Comment nous utilisons vos informations</h4>
      Vous pouvez voir une liste complète des types de données que nous traitons, le but pour lequel nous les traitons et la base légale sur laquelle elles sont traitées ici.

      <h4>Nous utilisons les informations détenues à votre sujet de la manière suivante :</h4>
      Nous pouvons utiliser vos informations personnelles aux fins suivantes :
      <ul>
        <li>S'assurer que <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> sont adaptés à vos besoins.</li>
        <li>Pour délivrer les services et informations concernant <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> que vous demandez.</li>
        <li>Pour assister <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> dans la création et la publication des contenus les plus pertinents pour vous.</li>
        <li>Pour vous alerter des informations mises à jour sur les services <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> si vous en faites la demande.</li>
        <li>Pour vous permettre d'accéder aux zones à accès limité de <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> selon le cas.</li>
        Dans certains cas, notre utilisation de vos informations est basée sur votre consentement. Dans d'autres cas, nous traitons vos informations personnelles parce qu'elles sont nécessaires pour fournir un service que vous avez demandé.
        Sinon, nous traitons vos informations personnelles sur la base de ses intérêts légitimes à utiliser vos données aux fins décrites dans la présente politique de confidentialité. Voici des exemples de nos intérêts légitimes :
        <li>Pour corriger les erreurs techniques et traiter techniquement vos informations personnelles</li>
        <li>Pour protéger la sécurité et l'intégrité de <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a></li>
        <li>Pour protéger l'un de nos biens ou droits ou obligations et/ou les biens, droits ou obligations de tiers où <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> peuvent avoir une obligation ou responsabilité à l'égard de ceux-ci et</li>
        <li>Pour se prémunir contre une éventuelle responsabilité de <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a>.</li>
        Pour nous assurer que nous équilibrons de manière appropriée nos intérêts légitimes avec vos droits et intérêts, vous avez le droit de vous opposer à un tel traitement à tout moment, comme décrit dans la section "Vos droits en matière de données personnelles".
        Nous pouvons également traiter et divulguer vos informations personnelles dans la mesure requise par la loi, la réglementation applicable ou la procédure judiciaire.
      </ul>

      <h4>Lorsque nous partageons vos informations avec</h4>

      <h4>Nous pouvons donner vos informations à :</h4>
      Tout membre de notre groupe, c'est-à-dire nos filiales, notre société holding ultime et ses filiales, qui soutiennent notre traitement des données personnelles dans le cadre de cette politique. Si l'une de ces parties utilise vos informations à des fins de marketing direct, nous ne leur transférerons les informations à cette fin qu'avec votre consentement préalable.

      <h4>Tiers sélectionnés.</h4>

      <h4>Nos tiers sélectionnés peuvent inclure :</h4>
      Les fournisseurs d'analyses et de moteurs de recherche qui nous aident à améliorer et à optimiser notre site et soumis à la section relative aux cookies de cette politique (cela ne vous identifiera pas en tant qu'individu).

      Partenaires commerciaux qui, conjointement avec nous, vous fournissent des services et avec lesquels nous avons conclu des accords relatifs au traitement de vos données personnelles.

      Fournisseurs de traitement des paiements qui fournissent des services de traitement des paiements sécurisés. Notez que vos informations de paiement ne nous sont pas communiquées par le fournisseur.

      <h4>Nous divulguerons vos informations personnelles à des tiers :</h4>
      Dans le cas où nous vendons ou achetons une entreprise ou des actifs, auquel cas nous divulguerons vos données personnelles au vendeur ou à l'acheteur potentiel de cette entreprise ou de ces actifs sous réserve des conditions de la présente politique de confidentialité.

      Si <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a> ou la quasi-totalité de ses actifs sont acquis par un tiers, auquel cas les données personnelles détenues par celui-ci sur ses clients seront l'une des les actifs transférés.

      Si nous sommes dans l'obligation de divulguer ou de partager vos données personnelles afin de nous conformer à toute obligation légale, ou afin de faire respecter ou d'appliquer nos conditions d'approvisionnement et autres accords avec vous ; ou pour protéger les droits, la propriété ou la sécurité de <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a>, nos clients ou autres. Cela inclut l'échange d'informations avec d'autres sociétés et organisations à des fins de protection contre la fraude et de réduction du risque de crédit et de prévention de la cybercriminalité.

      <h4>Où nous stockons vos informations</h4>
      Les données que nous recueillons auprès de vous peuvent être transférées et stockées dans une destination en dehors de l'Espace économique européen (« EEE ») qui peut ne pas être soumise à une loi équivalente sur la protection des données.

      Lorsque vos informations sont transférées en dehors de l'EEE, nous prendrons toutes les mesures raisonnablement nécessaires pour nous assurer que vos données sont soumises à des garanties appropriées, telles que le recours à un mécanisme d'adéquation juridique reconnu, et qu'elles sont traitées en toute sécurité et conformément à cette politique de confidentialité.

      <h4>Nous pouvons transférer vos informations personnelles en dehors de l'EEE</h4>

      <h4>Afin de le stocker</h4>
      Afin de nous permettre de vous fournir des services et de remplir notre contrat avec vous. Cela comprend l'exécution des services, le traitement des détails de paiement et la fourniture de services d'assistance.

      <h4>Comment nous stockons et sécurisons les informations que nous collectons</h4>

      <h4>Stockage et sécurité des informations</h4>
      Nous utilisons des mesures techniques et organisationnelles standard de l'industrie pour sécuriser les informations que nous stockons.
      Bien que nous mettions en œuvre des mesures de protection conçues pour protéger vos informations, aucun système de sécurité n'est impénétrable et, en raison de la nature inhérente d'Internet, nous ne pouvons garantir que les données, lors de leur transmission via Internet ou lorsqu'elles sont stockées sur nos systèmes ou sous notre garde, sont absolument à l'abri de l'intrusion d'autrui.

      <h4>Combien de temps conservons-nous les informations</h4>
      La durée pendant laquelle nous conservons les informations que nous recueillons à votre sujet dépend du type d'informations, comme décrit plus en détail ci-dessous. Passé ce délai, nous supprimerons ou anonymiserons vos informations ou, si cela n'est pas possible (par exemple, parce que les informations ont été stockées dans des archives de sauvegarde), nous stockerons vos informations en toute sécurité et les isolerons de toute utilisation ultérieure jusqu'à leur suppression. est possible.
      <br />
      <br />
      <strong>Informations sur le compte:</strong> Nous conservons les informations de votre compte aussi longtemps que votre compte est actif et pendant une période raisonnable par la suite au cas où vous décideriez de réactiver les Services. Nous conservons également certaines de vos informations si nécessaire pour nous conformer à nos obligations légales, pour résoudre les litiges, pour faire respecter nos accords, pour soutenir les opérations commerciales et pour continuer à développer et à améliorer nos Services. Lorsque nous conservons des informations pour l'amélioration et le développement du Service, nous prenons des mesures pour éliminer les informations qui vous identifient directement, et nous utilisons uniquement les informations pour découvrir des informations collectives sur l'utilisation de nos Services, et non pour analyser spécifiquement des caractéristiques personnelles vous concernant.
      <br />
      <br />
      <strong>Informations que vous partagez sur les Services:</strong> si votre compte est désactivé ou désactivé, certaines de vos informations et le contenu que vous avez fourni seront conservés afin de permettre aux membres de votre équipe ou à d'autres utilisateurs d'utiliser pleinement les Services.
      <br />
      <br />
      <strong>Informations marketing:</strong> si vous avez choisi de recevoir des e-mails marketing de notre part, nous conservons des informations sur vos préférences marketing pendant une période raisonnable à compter de la date à laquelle vous avez exprimé pour la dernière fois votre intérêt pour nos Services, par exemple lorsque vous avez ouvert un e-mail de notre part pour la dernière fois ou cessé en utilisant votre compte <a href="https://budget.bitbase.fr/">https://budget.bitbase.fr/</a>. Nous conservons les informations dérivées des cookies et d'autres technologies de suivi pendant une période raisonnable à compter de la date à laquelle ces informations ont été créées.
      <br />
      <br />

      <h4>Comment accéder et contrôler vos informations</h4>
      Certains choix s'offrent à vous en ce qui concerne vos informations. Vous trouverez ci-dessous un résumé de ces choix, de la manière de les exercer et de toute limitation.

      <h4>Vos choix :</h4>
      Vous avez le droit de demander une copie de vos informations, de vous opposer à notre utilisation de vos informations (y compris à des fins de marketing), de demander la suppression ou la restriction de vos informations, ou de demander vos informations dans un format électronique structuré. Ci-dessous, nous décrivons les outils et les processus pour effectuer ces demandes. Vous pouvez exercer certains des choix en vous connectant aux Services et en utilisant les paramètres disponibles dans les Services ou votre compte. Lorsque les Services sont administrés pour vous par un administrateur, vous devrez peut-être d'abord contacter votre administrateur pour répondre à vos demandes. Pour toutes les autres demandes, vous pouvez nous contacter comme indiqué dans la section Contactez-nous pour demander de l'aide.
      Si vous avez des préoccupations non résolues, vous pouvez avoir le droit de porter plainte auprès d'une autorité de protection des données dans le pays où vous vivez, où vous travaillez ou dans lequel vous estimez que vos droits ont été violés.
      <br />
      <br />
      <strong>Accéder à vos informations et les mettre à jour:</strong> nos Services et la documentation connexe vous permettent d'accéder à certaines informations vous concernant et de les mettre à jour depuis le Service. Par exemple, vous pouvez accéder aux informations de votre profil à partir de votre compte et rechercher du contenu contenant des informations vous concernant à l'aide de recherches par mots clés dans le Service. Vous pouvez mettre à jour les informations de votre profil dans les paramètres de votre profil et modifier le contenu contenant des informations vous concernant à l'aide des outils d'édition associés à ce contenu.
      <br />
      <br />
      <strong>Désactiver votre compte:</strong> Si vous ne souhaitez plus utiliser nos Services, vous pouvez peut-être désactiver votre compte Services. Si vous pouvez désactiver votre propre compte, ce paramètre est disponible dans les paramètres de votre compte.
      <br />
      <br />
      <strong>Supprimer vos informations:</strong> Nos Services et la documentation connexe vous permettent de supprimer certaines informations vous concernant depuis le Service.
      <br />
      <br />

      <strong>Demander que nous arrêtions d'utiliser vos informations:</strong> dans certains cas, vous pouvez nous demander d'arrêter d'accéder, de stocker, d'utiliser et de traiter vos informations lorsque vous pensez que nous n'avons pas les droits appropriés pour le faire. Si vous vous opposez au partage d'informations vous concernant avec une application tierce, veuillez désactiver l'application ou contacter votre administrateur pour le faire.
      <br />
      <br />

      <strong>Refuser les communications:</strong> vous pouvez refuser de recevoir des communications promotionnelles de notre part en utilisant le lien de désabonnement dans chaque e-mail, en mettant à jour vos préférences de messagerie dans les paramètres de votre compte de service. Vous pouvez refuser de recevoir des publicités personnalisées d'autres sociétés membres de la Network Advertising Initiative ou qui souscrivent aux principes d'autorégulation de la Digital Advertising Alliance pour la publicité comportementale en ligne. Pour plus d’informations sur cette pratique et pour comprendre vos options, veuillez visiter: http://www.aboutads.info, http://optout.networkadvertising.org/ et
      http://www.youronlinechoices.eu.
      <br />
      <br />

      <h4>Où nous sommes légalement tenus de le faire.</h4>
      Afin de faciliter notre opération, là où c'est dans nos intérêts légitimes et nous avons conclu que ceux-ci ne sont pas supplantés par vos droits.


      <h4>Bases légales du traitement (pour les utilisateurs de l'EEE) :</h4>
      Si vous êtes un particulier dans l'Espace économique européen (EEE), nous recueillons et traitons des informations vous concernant uniquement lorsque nous avons des bases légales pour le faire en vertu des lois applicables de l'UE. Les bases juridiques dépendent des Services que vous utilisez et de la manière dont vous les utilisez. Cela signifie que nous collectons et utilisons vos informations uniquement lorsque:
      <ul>
        <li>Nous en avons besoin pour vous fournir les Services, y compris pour exploiter les Services, fournir un support client et des fonctionnalités personnalisées et pour protéger la sûreté et la sécurité des Services</li>
        <li>Il satisfait un intérêt légitime (qui n'est pas supplanté par vos intérêts en matière de protection des données), comme pour la recherche et le développement, pour commercialiser et promouvoir les Services et pour protéger nos droits et intérêts légaux</li>
        <li>Vous nous donnez votre consentement pour le faire dans un but précis ou</li>
        <li>Nous devons traiter vos données pour nous conformer à une obligation légale.</li>
      </ul>

      Si vous avez consenti à ce que nous utilisions les informations vous concernant dans un but précis, vous avez le droit de changer d'avis à tout moment, mais cela n'affectera pas les traitements qui ont déjà eu lieu. Lorsque nous utilisons vos informations parce que nous ou un tiers avons un intérêt légitime à le faire, vous avez le droit de vous opposer à cette utilisation.

      <h4>Notre politique envers les enfants</h4>
      Les Services ne s'adressent pas aux personnes de moins de 13 ans. Nous ne collectons pas sciemment des informations personnelles d'enfants de moins de 13 ans. Si nous apprenons qu'un enfant de moins de 13 ans nous a fourni des informations personnelles, nous prendrons des mesures pour supprimer ces informations. Si vous apprenez qu'un enfant nous a fourni des informations personnelles, veuillez contacter nos services d'assistance.

      <h4>Comment nous protégeons vos informations</h4>
      Toutes les informations que vous nous fournissez sont stockées sur nos serveurs sécurisés. Toutes les transactions de paiement seront cryptées. Malheureusement, la transmission d'informations via Internet n'est pas totalement sécurisée. Bien que nous fassions de notre mieux pour protéger vos données personnelles, nous ne pouvons garantir la sécurité de vos données transmises à nos Plateformes; toute transmission est à vos risques et périls. Une fois que nous aurons reçu vos informations, nous utiliserons des procédures strictes et des fonctions de sécurité pour essayer d'empêcher tout accès non autorisé.

      Notre site peut, de temps à autre, contenir des liens vers des sites externes. Nous ne sommes pas responsables des politiques de confidentialité ou du contenu de ces sites.
      Combien de temps nous conservons vos informations
      Nous conservons les données personnelles aussi longtemps que vous avez un compte chez nous afin de respecter nos obligations contractuelles envers vous. Nous pouvons également conserver des informations agrégées à des fins de recherche et pour nous aider à développer et à améliorer nos services. Vous ne pouvez pas être identifié à partir des informations agrégées conservées ou utilisées à ces fins.

      <h4>Vos droits</h4>
      Vous avez le droit dans certaines circonstances:

      <ul>
        <li>recevoir une copie de vos données personnelles que nous détenons</li>
        <li>pour demander la rectification ou l'effacement de vos données personnelles que nous détenons</li>
        <li>pour nous demander de restreindre le traitement de vos données personnelles</li>
        <li>de vous opposer au traitement ultérieur de vos données personnelles, y compris le droit de vous opposer au marketing (comme mentionné dans la section « Nos mises à jour et communications promotionnelles » )</li>
        <li>de demander que vos données personnelles fournies soient transférées à un tiers</li>
      </ul>

      <h4>Votre droit de retirer votre consentement</h4>
      Lorsque le traitement de vos informations personnelles par nous est basé sur le consentement, vous avez le droit de retirer ce consentement sans préjudice à tout moment en nous contactant. Vous pouvez également modifier vos préférences marketing à tout moment.

      <h4>Conformité RGPD de l'Union européenne (Avis sur le traitement des données)</h4>
      Nous nous engageons à assurer la protection de votre vie privée. Cet avis de protection des données détaille les informations personnelles que nous pouvons collecter auprès de vous et comment nous pouvons utiliser ces informations.

      En nous fournissant vos informations personnelles, vous reconnaissez que nous pouvons les utiliser de la manière indiquée dans le présent accord. Nous pouvons vous fournir d'autres avis soulignant certaines utilisations que nous souhaitons faire de vos informations personnelles. Nous pouvons également vous donner la possibilité d'activer ou de désactiver certaines utilisations, telles que le marketing, lorsque nous collectons vos informations personnelles.

      En plus de cet accord, nos services et contenus peuvent avoir leurs propres avis, qui décrivent plus en détail comment vos informations personnelles sont utilisées dans un contexte particulier.

      <h4>Portabilité des données</h4>
      La portabilité des données est la possibilité d'obtenir certaines de vos informations dans un format que vous pouvez transférer d'un fournisseur de services à un autre. Selon le contexte, cela s'applique à certaines de vos informations, mais pas à toutes vos informations.

      <h4>Plainte auprès d'une autorité de protection des données</h4>
      Vous avez le droit de déposer une plainte concernant nos activités de traitement de données auprès de notre délégué à la protection des données.

      <h4>Nous contacter</h4>
      Si vous avez des questions concernant nos pratiques de collecte et de protection des données ou vos droits, n'hésitez pas à nous contacter.

      <h4>Modifications de la politique de confidentialité</h4>
      Si nous sommes impliqués dans une réorganisation, une fusion, une acquisition ou une vente de nos actifs, vos informations peuvent être transférées dans le cadre de cette transaction. Nous vous informerons de tout accord de ce type et vous indiquerons vos choix dans ce cas. Nous pouvons réviser cette politique de confidentialité de temps à autre et publierons la version la plus récente sur notre site Web. Si une révision réduit significativement vos droits, nous vous en informerons.

    </div>
  );
}
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import AccountRow from '../components/AccountRow';
import CreateRow from '../components/CreateRow';
import SubMenuAccount from '../components/SubMenuAccount';
import { UserContext } from '../contexts/UserContext';
import Api from '../services/Api';

export default function Account() {
  const { accountId } = useParams();
  const [account, setAccount] = useState({});
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const { setSelectedAccount } = useContext(UserContext);

  const handleDateUpdated = (urlParams, date) => {
    getData(urlParams);
    let maxDate = new Date(date.getTime());
    maxDate.setMonth(maxDate.getMonth() + 1);
    maxDate.setDate(maxDate.getDate() - 1);
    setMaxDate(maxDate);
    setMinDate(date.setDate(1));
  }

  const handleRowUpdated = (rowId, name, value) => {
    const accountEdited = { ...account };
    const row = accountEdited.row.find(r => r.id === rowId);
    if (row) {
      row[name] = value;
    }
    setAccount(accountEdited);
  }

  const getData = async (urlParams = '') => {
    const getAccount = await Api.get(`/accounts/with-rows/${accountId}${urlParams}`);
    if (!getAccount.data) {
      return;
    }
    const accountCloned = getAccount.data;
    setSelectedAccount({ id: accountCloned.id, title: accountCloned.title, color: accountCloned.color });
    accountCloned.categories_depense = accountCloned.categories?.filter(row => row.type === 1) || [];
    accountCloned.categories_revenu = accountCloned.categories?.filter(row => row.type === 2) || [];
    accountCloned.depenses = accountCloned.rows?.filter(row => row.type === 1) || [];
    accountCloned.revenus = accountCloned.rows?.filter(row => row.type === 2) || [];
    accountCloned.sumDep = accountCloned.depenses?.filter(row => row.category.neutral === 0).map(row => parseFloat(row.amount)).reduce((acc, val) => acc + val, 0) || 0;
    accountCloned.sumRev = accountCloned.revenus?.filter(row => row.category.neutral === 0).map(row => parseFloat(row.amount)).reduce((acc, val) => acc + val, 0) || 0;
    accountCloned.difference = accountCloned.sumRev - accountCloned.sumDep;
    setAccount(accountCloned);
  }

  return (
    <div className='grow'>
      <SubMenuAccount account={account} handleDateUpdated={(params, date) => handleDateUpdated(params, date)} />
      <div className="flex flex-col">
        <div className='p-2 md:p-6'>
          <table className='text-sm'>
            <thead>
              <tr>
                <th className='px-1 font-normal text-left w-32'>Date</th>
                <th className='px-1 font-normal text-left w-96'>Libellé</th>
                <th className='px-1 font-normal text-left w-32'>Catégorie</th>
                <th className='px-2 font-normal text-right w-32'>Montant</th>
                <th className='px-1 font-normal text-left w-8'></th>
                <th className='px-1 font-normal text-left w-6'></th>
              </tr>
            </thead>
            <tbody>
              {
                account.depenses && account.depenses?.map((row) =>
                  <AccountRow hasDate={true} hasCheckbox={true} key={row.id} minDate={minDate} maxDate={maxDate} row={row} categories={account.categories_depense} handleRowUpdated={(rowId, name, value) => handleRowUpdated(rowId, name, value)} />
                )
              }
              {
                <CreateRow hasDate={true} hasCheckbox={true} minDate={minDate} maxDate={maxDate} categories={account.categories_depense} />
              }
            </tbody>
          </table>


          <table className='text-sm mt-10'>
            <thead>
              <tr>
                <th className='px-1 font-normal text-left w-32'>Date</th>
                <th className='px-1 font-normal text-left w-96'>Libellé</th>
                <th className='px-1 font-normal text-left w-32'>Catégorie</th>
                <th className='px-2 font-normal text-right w-32'>Montant</th>
                <th className='px-1 font-normal text-left w-8'></th>
                <th className='px-1 font-normal text-left w-6'></th>
              </tr>
            </thead>
            <tbody>
              {
                account.revenus?.map((row) =>
                  <AccountRow hasDate={true} hasCheckbox={true} key={row.id} minDate={minDate} maxDate={maxDate} row={row} categories={account.categories_revenu} handleRowUpdated={(rowId, name, value) => handleRowUpdated(rowId, name, value)} />
                )
              }
              {
                <CreateRow hasDate={true} hasCheckbox={true} minDate={minDate} maxDate={maxDate} categories={account.categories_revenu} />
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
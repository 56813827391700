import { useEffect, useState } from "react";
import AccountCard from "../components/AccountCard";
import CreateAccount from "../components/CreateAccount";
import Api from "../services/Api";

export default function Accounts() {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const getAccounts = async () => {
      setAccounts(await (await Api.get('/accounts/')).data);
    }
    getAccounts();
  }, []);

  return (
    <div className='grow mt-3 md:mt-5 mx-3 md:mx-5'>
      {
        accounts && accounts.map(account => <AccountCard key={account.id} account={account} />)
      }
      <CreateAccount />
    </div>
  );
}
import { NavLink } from "react-router-dom";

export default function MenuOffline() {
  return (
    <>
      <NavLink to="/"><img className='w-40' src={process.env.PUBLIC_URL + `/logo.png`} alt="logo" /></NavLink>
      <div>
        <NavLink to="/enregistrement" className="hidden sm-block"><button className="btn btn-secondary mr-3">Inscription</button></NavLink>
        <NavLink to="/connexion"><button className="btn btn-primary">Connexion</button></NavLink>
      </div>
    </>
  );
}

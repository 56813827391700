import { NavLink } from 'react-router-dom';

export default function Home() {
  return (
    <div className='grow bg-neutral-100'>
      <div>
        <div className='homepage-background uppercase text-center px-3 md:px-0'>
          <h1 className='relative font-bold text-lg sm:text-3xl text-gray-800 my-1'>comment gérez-vous vos compte ?</h1>
          <h2 className='relative font-medium text-sm sm:text-2xl text-gray-500 my-1'>vous pouvez désormais utiliser notre application de gestion de budget</h2>
          <NavLink to="/enregistrement"><button className='relative btn btn-primary my-2'>Créer un compte</button></NavLink>
        </div>
      </div>
      <div className='max-w-full w-full md:w-3/5 mx-auto text-sm sm:text-base text-justify px-4 md:px-0 my-5 py-10 text-gray-500'>
        <p className='py-3'>Budget est l'application de gestion de budget qui vous permet de faire vos comptes numériquement, et de pouvoir y avoir accès depuis partout dans la monde. Cela vous permet de gagner un temps précieux par rapport aux méthodes traditionnelles (manuscrites par exemple). Vous pouvez catégoriser les dépenses et les revenus, effectuer des recherches très rapidement, de visualiser graphiquement les montants et d'avoir des bilans par mois ou par année. Nous sommes fiers de présenter Budget.</p>
        <p className='py-3'>Budget est un outil puissant, vous pouvez définir des catégories (par exemple: dépenses pour la maison, dépenses en éléctricité, dépenses pour les loisirs, etc.) et chaque catégorie peut être répertoriée plus en détails (par exemple: achats de carburant, achats de nourriture, achats de livres, achats d'habits, etc.). Vous pouvez ajouter autant comptes, livrets que vous souhaitez. Budget présente les montants sous forme de graphiques pour que vous puissiez voir rapidement ce qui coûte cher et comparer les montants des mois précédents.</p>
        <p className='py-3'>Budget est la meilleure application de gestion de budget qui vous aide à atteindre votre objectif financier. Vous pouvez commencer à utiliser Budget dès maintenant pour vous aider à organiser vos revenus et dépenses!</p>
      </div>
    </div>
  );
}

import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import checked from '../icons/checked.svg';
import unchecked from '../icons/unchecked.svg';
import CategoriesSelect from './CategoriesSelect';

export default function AccountRow({ row, categories, handleRowUpdated, minDate, maxDate, hasCheckbox, hasDate }) {
  const [currentRow, setCurrentRow] = useState({
    description: row.description,
    amount: row.amount,
    categoryId: row.categoryId,
    date: row.date,
    isChecked: row.isChecked
  });

  const rowUpdated = (name, value) => {
    const rowEdited = { ...currentRow };
    rowEdited[name] = value;
    setCurrentRow(rowEdited);
  }

  return (
    <tr>
      {
        !hasDate ? null : <td className="px-1 w-12 border border-gray-300 text-gray-500">
          <ReactDatePicker
            className="bg-transparent outline-none w-full"
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => rowUpdated(row.id, 'date', date)}
            selected={currentRow.date ? new Date(currentRow.date) : currentRow.date}
            dateFormat="dd/MM/yyyy"
            locale='fr' />
        </td>
      }
      <td style={{ backgroundColor: row.category.color + '80' }} className=" ">
        <input onChange={(e) => rowUpdated(row.id, 'description', e.target.value)} className="px-1 bg-transparent outline-none w-full" type="text" name="description" value={currentRow.description} />
      </td>
      <td style={{ backgroundColor: row.category.color + '80' }} className=" ">
        <CategoriesSelect handleCategoryChanged={(value) => rowUpdated(row.id, 'category', value)} categories={categories} category={currentRow.categoryId} />
      </td>
      <td style={{ backgroundColor: row.category.color + '80' }} className="px-1 text-right pr-2">
        <input onChange={(e) => rowUpdated(row.id, 'amount', e.target.value)} className="px-1 bg-transparent outline-none text-right" type="text" style={{ width: 'calc(100% - 16px)' }} name="amount" value={currentRow.amount} /> €
      </td>
      {
        !hasCheckbox ? null :
          <td className="px-1 border border-gray-300">
            <img onClick={() => rowUpdated(row.id, 'isChecked', currentRow.isChecked === 1 ? 0 : 1)} className='w-5' src={currentRow.isChecked === 1 ? checked : unchecked} alt="pointer ou dépointer" />
          </td>
      }
      <td className="px-1"></td>
    </tr>
  );
}

import { NavLink } from "react-router-dom";
import plus from '../icons/plus-solid.svg';

export default function CreateAccount() {

  return (
    <div style={{ maxWidth: 300 }} className="inline-block w-full m-auto mx-3 hover:scale-105 ease-in duration-200">
      <NavLink to={'/compte/creation'} style={{ border: '1px solid gray', borderLeft: '8px solid grey' }}
      className="relative white-box xs2-box m-2 flex flex-col justify-between h-32 p-3 rounded-none rounded-r-lg border bg-no-repeat bg-right">
        <img src={plus} alt="créer un compte" className="absolute w-16 right-1.5 bottom-1" />
        <div className="text-md font-semibold">Créer un nouveau compte</div>
        <div className="self-end"></div>
      </NavLink>
    </div>
  );
}

import { useState } from "react";
import { NavLink } from "react-router-dom";
import account from '../icons/account.svg'
import ProfileMenu from "./ProfileMenu";

export default function MenuLogged({ selectedAccount }) {
  const [displayProfileMenu, setDisplayProfileMenu] = useState(false);

  const toggleProfileMenu = () => {
    setDisplayProfileMenu(!displayProfileMenu);
  }

  return (
    <>
      <NavLink to="/comptes" className="menu-logo">
        <img className='w-40' src={process.env.PUBLIC_URL + `/logo.png`} alt="logo" />
      </NavLink>
      <ul className="hidden sm:flex items-center grow pl-5 uppercase">
        <li className="px-4"><NavLink to={`/compte/${selectedAccount?.id}`}>{selectedAccount?.title}</NavLink></li>
        <li className="px-4"><NavLink to={`/charges-fixes/${selectedAccount?.id}`}>Charges fixes</NavLink></li>
        <li className="px-4"><NavLink to="/categories">Catégories</NavLink></li>
        <li className="px-4"><NavLink to="/graphique">Graphique</NavLink></li>
        <li className="px-4"><NavLink to="/recherche">Recherche</NavLink></li>
        <li className="px-4"><NavLink to="/bilan">Bilan</NavLink></li>
        <li>{displayProfileMenu}</li>
      </ul>

      <button className="relative">
        <div onClick={() => toggleProfileMenu()} onBlur={() => toggleProfileMenu()} className="border-2 border-transparent hover:border-black p-0 rounded-lg" >
          <img src={account} alt="mon compte" />
        </div>
        {displayProfileMenu ? <ProfileMenu /> : ''}
      </button>

    </>
  );
}

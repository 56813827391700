import { NavLink } from "react-router-dom";

export default function ProfileMenu() {
  return (
    <div className="absolute top-12 right-0 bg-white shadow z-10 rounded">
      <ul>
        <NavLink to="/deconnexion"><li className="rounded ease-in duration-300 hover:bg-gray-200 px-5 py-2">Déconnexion</li></NavLink>
      </ul>
    </div>
  );
}

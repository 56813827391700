import { Routes, Route } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import { Globals } from "./services/Globals";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import Account from "./pages/Account";
import Accounts from "./pages/Accounts";
import CookiePolicy from "./pages/CookiePolicy";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Register from "./pages/Register";
import TermsOfUse from "./pages/TermsOfUse";
import fr from "date-fns/locale/fr";
import CreationAccount from "./pages/CreationAccount";
import Logout from "./pages/Logout";
import UserContextProvider from "./contexts/UserContext";
import FixedRows from "./pages/FixedRows";
registerLocale("fr", fr);


function App() {
  return (
    <div className="flex flex-col h-screen justify-between">
      <UserContextProvider>
        <Menu logged={Globals.isAuthenticated()} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/connexion" element={<Login />} />
          <Route path="/deconnexion" element={<Logout />} />
          <Route path="/enregistrement" element={<Register />} />
          <Route path="/comptes" element={<Accounts />} />
          <Route path="/compte/creation" element={<CreationAccount />} />
          <Route path="/compte/:accountId" element={<Account />} />
          <Route path="/charges-fixes/:accountId" element={<FixedRows />} />
          <Route path="/politique-cookie" element={<CookiePolicy />} />
          <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
          <Route path="/condition-generale-utilisation" element={<TermsOfUse />} />
        </Routes>
        <Footer />
      </UserContextProvider>
    </div>
  );
}

export default App;

import React, { useState, useEffect, createContext } from "react";
import Api from "../services/Api";
import { Globals } from "../services/Globals";

export const UserContext = createContext();

function UserContextProvider(props) {
  const [user, setUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    if (Globals.isAuthenticated()) {
      const getUser = async () => {
        const user = await Api.get("/users");
        setUser(user.data);
        if (user.data.accounts) {
          setSelectedAccount(user.data.accounts[0]);
        }
      };

      getUser();
    }
  }, []);
  const value = { user, setUser, selectedAccount, setSelectedAccount };
  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
import { useContext, useEffect } from 'react';
import Api from '../services/Api';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';

export default function Logout() {
  const { setUser, setSelectedAccount } = useContext(UserContext);

  let navigate = useNavigate();
  useEffect(() => {
    setUser(null);
    setSelectedAccount({color: '#FFFFFF'});
    Api.get('/auth/logout');
    return navigate("/");
    // eslint-disable-next-line
  }, []);

  return (
    <>
    </>
  );
}
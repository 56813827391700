import { useContext, useState } from 'react';
import Api from '../services/Api';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';

export default function Login() {
  let navigate = useNavigate();
  const { setUser, setSelectedAccount } = useContext(UserContext);
  const [login, setLogin] = useState({
    email: '',
    password: ''
  });

  function handleChange(evt) {
    const { name, value } = evt.target;
    setLogin({ ...login, [name]: value });
  }

  async function handleSubmit(evt) {
    try {
      evt.preventDefault();
      const user = await Api.post('/auth/login', login);
      setUser(user.data);
      if (user.data.accounts?.length > 0) {
        setSelectedAccount(user.data.accounts[0]);
      }
      return navigate("/comptes");
    } catch (e) {
      console.log('Une erreur est surviendu', e);
    }
  }

  return (
    <div className='sm-box white-box text-center uppercase py-12 border'>
      <h2 className='font-bold text-2xl text-gray-600'>Connexion <span className='text-4xl text-primary'>B</span>udget</h2>
      <form onSubmit={(evt => handleSubmit(evt))}>
        <div className='flex flex-col xs-box'>
          <label className='mt-3 text-gray-500 text-xs' htmlFor="email">Email</label>
          <input className='input' type="text" name="email" id="email" onChange={(evt) => handleChange(evt)} />
          <label className='text-gray-500 text-xs' htmlFor="password">Mot de passe</label>
          <input className='input' type="password" name="password" id="password" onChange={(evt) => handleChange(evt)} />
          <button className='btn btn-primary' type="submit">Connexion</button>
        </div>
      </form>
    </div>
  );
}
import { useState } from 'react';
import Api from '../services/Api';

export default function Register() {
  const [user, setUser] = useState({
    email: '',
    emailConfirm: '',
    password: ''
  });

  function handleChange(evt) {
    const { name, value } = evt.target;
    setUser({ ...user, [name]: value });
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    Api.post('/auth/register', user);
  }

  return (
    <div className='sm-box white-box text-center uppercase py-12 border'>
      <h2 className='font-bold text-2xl text-gray-600'>Bienvenue sur <span className='text-4xl text-primary'>B</span>udget</h2>
      <form onSubmit={(evt => handleSubmit(evt))}>
        <div className='flex flex-col xs-box'>
          <label className='mt-3 text-gray-500 text-xs' htmlFor="email">Email</label>
          <input className='input' type="text" name="email" id="email" onChange={(evt) => handleChange(evt)} />
          <label className='text-gray-500 text-xs' htmlFor="emailConfirm">Confirmation email</label>
          <input className='input' type="text" name="emailConfirm" id="emailConfirm" onChange={(evt) => handleChange(evt)} />
          <label className='text-gray-500 text-xs' htmlFor="password">Mot de passe</label>
          <input className='input' type="password" name="password" id="password" onChange={(evt) => handleChange(evt)} />
          <button className='btn btn-primary' type="submit">Créer mon compte</button>
        </div>
      </form>
    </div>
  );
}
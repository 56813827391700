export default function CategoriesSelect({ categories, category, handleCategoryChanged }) {
  return (
    !categories ? null :
      <select className="bg-transparent w-full outline-none" value={category} onChange={(evt) => handleCategoryChanged(evt.target.value)}>
        {
          categories?.map(categorie => <option key={categorie.id} style={{ backgroundColor: categorie.color + '80' }} value={categorie.id}>{categorie.label}</option>)
        }
      </select>
  );
}

import ellipsis from '../icons/ellipsis.svg';
import trendup from '../icons/arrow-trend-up.svg';
import trenddown from '../icons/arrow-trend-down.svg';
import DisplayAmount from './DisplayAmount';
import Tooltip from './Tooltip';

export default function SubMenuFixedRows({ account }) {
  return (
    <div className='white-box px-3 py-3 flex flex-row justify-between border-b border-gray-300'>
      <div className='flex flex-row items-center'>
        <div className='font-bold uppercase mr-5'><span className='mr-5'>{account?.title}</span>
          <Tooltip content="Options" direction="top">
            <button>
              <img src={ellipsis} alt="voir option" className='inline-block w-4' />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className='flex flex-row items-center'>
        <Tooltip content="Revenus du mois" direction="top">
          <img src={trendup} alt="revenu" className='mr-2 inline-block w-4 text-green-600' /> {<DisplayAmount value={account.sumRev} />} €
        </Tooltip>
        <Tooltip content="Dépenses du mois" direction="top">
          <img src={trenddown} alt="depense" className='ml-4 mr-2 inline-block w-4' /> {<DisplayAmount value={account.sumDep} />} €
        </Tooltip>
        <div className='border-l-2 ml-4'>
          <Tooltip content="Bilan du mois" direction="top">
            <img src={account.difference < 0 ? trenddown : trendup} alt="difference" className='ml-4 mr-2 inline-block w-4' /> {<DisplayAmount value={account.difference} />} €
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
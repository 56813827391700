import '../css/documents.css';

export default function CookiePolicy() {
  return (
    <div className='document md-box white-box text-justify my-10 py-12 border'>
      (Dernière mise à jour le 28 Janvier 2022)
      <h4>Aperçu</h4>
      Les sites Web utilisent des cookies pour vous distinguer des autres utilisateurs et cela nous aide
      à vous offrir une bonne expérience lorsque vous naviguez sur nos sites Web et également à
      améliorer nos sites Web.
      Les cookies nous aident à vous offrir une meilleure expérience client lorsque vous naviguez sur
      nos sites Web et nous permettent d'améliorer et d'adapter les sites Web pour vous. Par exemple,
      nous gardons une trace des domaines à partir desquels les gens visitent et nous mesurons
      également l'activité des visiteurs sur les sites Web. Nous utilisons les informations que nous
      recueillons pour mesurer le nombre de visiteurs dans les différentes zones de nos sites Web afin
      d'améliorer la fonctionnalité du site Web et de nous assurer que nous pouvons traiter et exécuter
      vos commandes.
      Si vous souhaitez supprimer les cookies placés sur votre appareil par nos sites Web ou empêcher
      nos sites Web de placer d'autres cookies sur votre appareil, vous pouvez le faire à tout moment,
      découvrez comment procéder ci-dessous, dans la section gestion des cookies.
      En visitant notre site Web avec les paramètres de votre navigateur ajustés pour autoriser les
      cookies ou en utilisant notre application, vous consentez à l'utilisation de cookies non essentiels
      et reconnaissez notre utilisation de cookies essentiels aux fins décrites ci-dessous afin de vous
      offrir une expérience entièrement fonctionnelle.
      <h4>Définitions et références légales</h4>
      <h4>Données personnelles (ou données)</h4>
      Toute information qui directement, indirectement ou en relation avec d'autres informations - y
      compris un numéro d'identification personnel - permet l'identification ou l'identifiabilité d'une
      personne physique.
      <h4>Des données d'utilisation</h4>
      Informations collectées automatiquement via ce site Web (ou des services tiers employés sur ce
      site Web), qui peuvent inclure : les adresses IP ou les noms de domaine des ordinateurs utilisés
      par les utilisateurs qui utilisent ce site Web, les adresses URL, l'heure de la demande, la méthode
      utilisée pour soumettre la requête au serveur, la taille du fichier reçu en réponse, le code
      numérique indiquant l'état de la réponse du serveur, le pays d'origine, les fonctionnalités du
      navigateur et le système d'exploitation utilisé par l'utilisateur, les différents détails de temps par
      visite et les détails sur le chemin suivi dans l'application avec une référence particulière à la
      séquence des pages visitées, et d'autres paramètres sur le système d'exploitation de l'appareil
      et/ou l'environnement informatique de l'utilisateur.
      <h4>Utilisateur</h4>
      La personne utilisant ce site Web qui, sauf indication contraire, coïncide avec la personne
      concernée.
      <h4>Personne concernée</h4>
      La personne physique à laquelle se réfèrent les données personnelles.
      <h4>Processeur de données (ou superviseur de données)</h4>
      La personne physique ou morale, l'autorité publique, l'agence ou tout autre organisme qui traite
      les données personnelles pour le compte du responsable du traitement, comme décrit dans la
      présente politique de confidentialité.
      <h4>Contrôleur de données (ou propriétaire)</h4>
      La personne physique ou morale, l'autorité publique, l'agence ou tout autre organisme qui, seul
      ou conjointement avec d'autres, détermine les finalités et les moyens du traitement des données
      personnelles, y compris les mesures de sécurité concernant le fonctionnement et l'utilisation de
      ce site Web. Le responsable du traitement, sauf indication contraire, est le propriétaire de ce site
      Web.
      <h4>Ce site Web (ou cette application)</h4>
      Les moyens par lesquels les données personnelles de l'utilisateur sont collectées et traitées.
      <h4>Service</h4>
      Le service fourni par ce site Web tel que décrit dans les conditions relatives (si disponibles) et sur
      ce site/application.
      <h4>Union européenne (ou UE)</h4>
      Sauf indication contraire, toutes les références faites dans ce document à l'Union européenne
      incluent tous les États membres actuels de l'Union européenne et de l'Espace économique
      européen.
      <h4>Cookies</h4>
      Petits ensembles de données stockés dans l'appareil de l'utilisateur.
      <h4>Qu'est-ce qu'un cookie ?</h4>
      Un cookie est un petit fichier texte qui est téléchargé et stocké sur votre ordinateur ou appareil
      mobile par les sites Web que vous visitez. Lorsque la technologie des cookies n'est pas disponible,
      un identifiant anonyme peut être utilisé. Un identifiant anonyme est une chaîne de caractères
      aléatoire utilisée aux mêmes fins qu'un cookie.
      Votre navigateur accède au fichier cookie uniquement lorsque vous visitez le site Web qui l'a
      généré. Cela facilite votre navigation en vous connectant automatiquement et en mémorisant
      des éléments tels que vos préférences et le contenu de votre panier. Les cookies permettent à
      des sites Web comme le nôtre de vous offrir une expérience d'achat personnalisée.
      Les informations stockées dans un cookie donné ne sont accessibles que par le site Web qui l'a
      créé et les cookies se limitent à communiquer uniquement les informations que vous avez
      divulguées au site.
      <h4>Gestion des cookies: activation et désactivation</h4>
      Vous pouvez configurer votre navigateur pour qu'il refuse tout ou partie des cookies du
      navigateur, ou pour qu'il vous avertisse lorsque des sites Web installent ou accèdent à des
      cookies. Si vous désactivez ou refusez les cookies, veuillez noter que certaines parties de ce site
      Web peuvent devenir inaccessibles ou ne pas fonctionner correctement.
      Si les cookies ne sont pas activés sur votre ordinateur, cela signifie que votre expérience sur notre
      site Web sera limitée à la navigation et à la recherche ; vous ne pourrez pas utiliser la plupart de
      nos fonctionnalités. Pour activer et gérer les cookies, vous pouvez utiliser votre navigateur pour
      ce faire. Chaque navigateur est différent, alors consultez le navigateur concerné (ou le manuel
      de votre téléphone mobile) pour savoir comment modifier vos préférences en matière de
      cookies.
      <ul>
        <li>Google Chrome</li>
        <li>Internet Explorer</li>
        <li>Mozilla Firefox</li>
        <li>Safari</li>
        <li>Opera</li>
        <li>Google Analytics Opt Out</li>
      </ul>
      <h4>Comment utilisons-nous les cookies ?</h4>
      Les cookies utilisés sur notre site Web sont regroupés dans les catégories suivantes:
      <ol>
        <li>Essentiel - il s'agit des cookies nécessaires au fonctionnement régulier de nos sites Web.
          Par exemple, certains cookies vous permettent de vous connecter à votre compte et
          d'effectuer des détections de fraude et des contrôles de sécurité (entre autres choses
          similaires). Ceux-ci sont essentiels au bon fonctionnement du site Web.</li>
        <li>Fonctionnels - ces cookies vous permettront de vous déplacer sur le site et d'utiliser ses
          fonctionnalités telles que l'accès à des zones sécurisées (par exemple, le contenu pour les
          utilisateurs enregistrés). Ces cookies nous permettent également de mémoriser vos
          préférences d'utilisateur, vous aidant à personnaliser votre expérience sur notre site. Les
          informations obtenues grâce à l'utilisation de cookies fonctionnels sont anonymes et ne
          sont utilisées à aucune autre fin.</li>
        <li>Améliorateurs de performances - ces cookies nous aideront à comprendre l'efficacité de
          notre contenu, ce qui intéresse nos clients et à améliorer le fonctionnement de notre site
          Web. Ces informations sont utilisées à des fins statistiques uniquement et ne sont pas
          utilisées pour identifier personnellement un utilisateur.</li>
        <li>Publicité - ces cookies nous permettent, ainsi qu'à nos partenaires publicitaires, de
          diffuser des annonces et de gérer notre publicité en ligne lorsque vous visitez notre site
          et les sites sur lesquels nous faisons de la publicité. Ces cookies enregistrent votre visite
          sur notre site Web et le contenu avec lequel vous interagissez, et peuvent également être
          utilisés pour gérer le nombre de fois que vous voyez une publicité.</li>
      </ol>

      <h4>Nous utilisons les types de cookies suivants</h4>
      Cookies essentiels:
      <ul>
        <li>Cookies strictement nécessaires. Il s'agit de cookies nécessaires au fonctionnement de
          nos sites Web. Ils comprennent, par exemple, des cookies qui vous permettent de vous
          connecter à des zones sécurisées de nos sites Web ou d'effectuer des achats. Nous
          n'avons pas besoin de votre consentement pour placer ces cookies. Néanmoins, vous
          pourrez peut-être bloquer ces cookies vous-même sur votre appareil/navigateur, mais la
          restriction de ces cookies signifiera probablement que nos sites Web ne fonctionneront
          pas comme prévu et que certaines fonctionnalités peuvent être inopérantes.</li>
        <li>Cookies analytiques/de performance. Ils nous permettent de reconnaître et de compter
          le nombre de visiteurs et de voir comment les visiteurs se déplacent sur nos sites Web
          lorsqu'ils l'utilisent. Cela nous aide à améliorer le fonctionnement de nos sites Web, par
          exemple en garantissant que les utilisateurs trouvent facilement ce qu'ils recherchent.
          Nous utilisons des cookies pour compiler des statistiques sur les visiteurs, telles que le
          nombre de personnes ayant visité nos sites Web, la manière dont elles ont accédé à nos
          sites Web, le type de technologie qu'elles utilisent (par exemple mac ou windows qui aide
          à identifier quand notre site ne fonctionne pas comme il le devrait pour technologies
          particulières), combien de temps ils passent sur les sites Web, quelle page ils consultent,
          etc. Cela nous aide à améliorer continuellement nos sites Web.</li>
      </ul>
      Cookies non essentiels
      <ul>
        <li>Cookies de fonctionnalité. Ceux-ci sont utilisés pour vous reconnaître lorsque vous
          revenez sur nos sites Web. Cela nous permet de personnaliser notre contenu pour vous,
          de vous saluer par votre nom et de mémoriser vos préférences (par exemple, votre choix
          de langue ou de région).</li>
        <li>Cookies de performance. Ces cookies nous permettent d'améliorer la fonctionnalité et
          l'expérience de notre site, ceux-ci incluent des cookies tiers qui collectent des
          informations pour aider à mieux adapter notre publicité à vos intérêts, préférences et
          activités passées.</li>
        <li>Cookies de ciblage. Ces cookies enregistrent votre visite sur nos sites Web, les pages que
          vous avez visitées et les liens que vous avez suivis. Ces informations seront utilisées par
          nous et par des tiers pour rendre nos sites Web et les publicités qui y sont affichées plus
          pertinents par rapport à vos intérêts. Nous pouvons également partager ces informations
          avec des tiers à cette fin, mais uniquement si vous consentez à un tel partage.</li>
      </ul>
      <h4>Modifications de cette politique de cookies et plus d'informations</h4>
      Toute modification future de cette politique de cookies sera publiée sur cette page et nous
      prendrons toutes les mesures nécessaires pour vous communiquer toute modification de cette
      politique de cookies. Veuillez vérifier fréquemment pour voir les mises à jour ou les modifications
      apportées à cette politique de cookies. Nous attirerons également votre attention sur ces
      changements en mettant à jour notre bannière/pop-up de cookies.
      Si vous avez d'autres questions, commentaires ou demandes concernant notre politique en
      matière de cookies ou la manière dont nous utilisons les cookies sur nos sites Web, vous pouvez
      nous contacter.
    </div>
  );
}
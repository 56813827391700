import { useState } from 'react';
import Api from '../services/Api';

export default function CreationAccount() {
  const [user, setUser] = useState({
    title: '',
    description: '',
    password: ''
  });

  function handleChange(evt) {
    const { name, value } = evt.target;
    setUser({ ...user, [name]: value });
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    Api.post('user/register', user);
  }

  return (
    <div className='sm-box white-box text-center uppercase py-12 border'>
      <h2 className='font-bold text-2xl text-gray-600'>Nouveau compte</h2>
      <form onSubmit={(evt => handleSubmit(evt))}>
        <div className='flex flex-col xs-box'>
          <label className='mt-3 text-gray-500 text-xs' htmlFor="title">Nom du compte</label>
          <input className='input' type="text" name="title" id="title" onChange={(evt) => handleChange(evt)} />
          <label className='text-gray-500 text-xs' htmlFor="description">Description</label>
          <input className='input' type="text" name="description" id="description" onChange={(evt) => handleChange(evt)} />
          <label className='text-gray-500 text-xs' htmlFor="initialSolde">Solde initial</label>
          <input className='input' type="text" name="initialSolde" id="initialSolde" onChange={(evt) => handleChange(evt)} />
          <button className='btn btn-primary' type="submit">Créer</button>
        </div>
      </form>
    </div>
  );
}